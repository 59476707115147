import React from "react";
import { Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';

const SubCategory = ({ subCatList = [], handleSubCategoryClick }) => {
    return (
        subCatList && subCatList.length !== 0 ?
            subCatList.map((subCat, index) => {
                return (
                    <Col md={12} key={index} >
                        <OverlayTrigger placement='top' overlay={<Tooltip>{subCat.catAlias || ""}</Tooltip>}>
                            <Button name={subCat.catAlias || ""} className={`light-btn w-100 text-truncate h-56 ${subCat.className ? subCat.className : "text-secondary"}`} variant="primary" onClick={() => handleSubCategoryClick(subCat)}>{subCat.catAlias || ""}</Button>
                        </OverlayTrigger>
                    </Col >
                )
            })
            : null
    )
}

export default SubCategory;