import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Row, Col, Container, Form, Nav, } from 'react-bootstrap';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { LuSearch } from 'react-icons/lu';
import { FaRotateLeft } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import { isAuthenticated } from '../../../config/appSession';
import { useNavigate } from 'react-router-dom';
import { getOpenOrders, updateOrder } from '../../../actions/order/OpenOrdersActions';
import { getOrderTypeMethods } from '../../../actions/billdesk/BillDeskActions';
import { deepCopy, standardDateFormat } from '../../../util/utility-helper';
import TokenOrderDetailModal from './modals/TokenOrderDetailModal';
import SingleOrderDetailModal from './modals/SingleOrderDetailModal';
import ToastManager from '../../../components/common/App/NotificationManager';
import OpenOrderTable from './widgets/Open-OrderTable';
import { getBussinessStages } from '../../../actions/order/OrderManage';
import ConfirmDialog from '../../../components/common/App/ConfirmDialog';
import UploadTrackingNoModal from './modals/UploadTrackingNoModal';

let cloneOrderList = {};
let selectedOrder = null;

const pageSizeSelectOptions = [{ name: "10", value: 10 }, { name: "20", value: 20 }, { name: "50", value: 50 }];
const initialPageDetailsState = { currentPage: 1, pageSize: 10 };
const initialModalManagerState = { modalName: '', visible: false };
const initialToastState = { visible: false, toastMessage: '', variant: "" };
const initalSearchFilterState = { searchText: "", orderStage: '' }
const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";

const OpenOrders = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /**
     * component states
     */
    const [openOrders, setOpenOrders] = useState([]);
    const [pageDetails, setPageDetails] = useState({ ...initialPageDetailsState });
    const [loading, setLoading] = useState(false);
    const [orderTypeMethods, setOrderTypeMethods] = useState([]);
    const [currentOrderType, setCurrentOrderType] = useState(null);
    const [orderListByType, setOrderListByType] = useState([]);
    const [modalManager, setModalManager] = useState({ ...initialModalManagerState });
    const [toastMessage, setToastMessage] = useState({ ...initialToastState });
    const [orderStages, setOrderStages] = useState([]);
    const [searchFilter, setSearchFilter] = useState({ ...initalSearchFilterState });

    useEffect(() => {
        checkAuthentication();
    }, [dispatch]);

    /**
     * check user is authenticated and get order type and open-order list
     */
    const checkAuthentication = async () => {
        if (isAuthenticated()) {
            setLoading(true);
            const currSelOrderType = await getOrderTypes();
            setCurrentOrderType(currSelOrderType)
            await getOpenOrderList(currSelOrderType || "", pageDetails);
            await getOrderStagesByApi(currSelOrderType);
        } else {
            navigate('/user/login');
        }
    };

    /**
     * fetch order types by calling api
     */
    const getOrderTypes = async () => {
        let selectedOrderType = null;
        await dispatch(getOrderTypeMethods())
            .then((response) => {
                if ((response.payload && !response.payload.error)) {
                    if (!currentOrderType) {
                        selectedOrderType = Array.isArray(response.payload) && response.payload.length > 0 ? response.payload[0].methodCode : null;
                    }
                    setOrderTypeMethods(response.payload);
                }
            });
        return selectedOrderType;
    };

    const getOrderStagesByApi = async (currSelOrderType) => {
        await dispatch(getBussinessStages())
            .then((response) => {
                if (response.payload && !response.payload.error) {
                    const stagesByMethods = response.payload.find(e => e.method === currSelOrderType);
                    setOrderStages(stagesByMethods && stagesByMethods?.transitions || [])
                }
            })
    }

    /**
     * fetch open-order list by calling api
     * @param {string} currentOrderType 
     * @param {object} pageDetails 
     */
    const getOpenOrderList = async (currentOrderType, pageDetails) => {
        await dispatch(getOpenOrders())
            .then((response) => {
                if (response.payload) {
                    cloneOrderList = response.payload;
                    setLoading(false);
                    sortOrdersByOrderType(response.payload, currentOrderType, pageDetails.currentPage);
                }
            })
    };

    /**
     * format list and filter the records by current selected ordertype
     * @param {Array} orderList 
     * @param {string} orderType 
     * @param {object} currentPage 
     */
    const sortOrdersByOrderType = async (orderList, orderType, currentPage) => {
        if (orderType === 'DINE') {
            const tokenOrders = orderList.tokenOrders || [];
            const openOrderList = [];
            await tokenOrders && tokenOrders.forEach(orderItm => {
                if (orderItm.orders && orderItm.orders.length >= 1) {
                    if (orderItm.orders.length > 1) {
                        let orderDate = '';
                        let orderTotal = 0;
                        let amountPaid = 0;
                        let paymentStatus = 'PAID';
                        let orderStage = 'WIP';
                        const sortedArrByDate = orderItm.orders.sort((a, b) => { return new Date(b.orderDate).getTime() - new Date(a.orderDate).getTime() });
                        orderItm.orders.forEach(orders => {
                            if (orders.orderTotal) {
                                orderTotal = parseFloat(parseFloat(orderTotal) + parseFloat(orders.orderTotal)).toFixed(2);
                            }
                            if (orders.amountPaid) {
                                amountPaid = (parseFloat(orders.amountPaid) + parseFloat(amountPaid)).toFixed(2);
                            }
                            if (orders.paymentStatus === 'UNPAID' || orders.paymentStatus === 'PARTIAL') {
                                paymentStatus = orders.paymentStatus;
                            }
                            if (orders.orderStage !== 'WIP') {
                                orderStage = orders.orderStage
                            }
                        })
                        if (sortedArrByDate && sortedArrByDate.length > 0) {
                            orderDate = standardDateFormat(sortedArrByDate[0].orderDate || '', dateTimeFormat);
                        }
                        openOrderList.push({ id: orderItm.id, tag: orderItm.tag, orderDate: orderDate, orderStage: orderStage, deliveryMethod: "DINE", orderTotal: orderTotal, paymentStatus: paymentStatus, amountPaid: amountPaid });
                        return openOrderList;
                    } else {
                        openOrderList.push({ id: orderItm.id, tag: orderItm.tag, orderDate: standardDateFormat(orderItm.orders[0].orderDate || '', dateTimeFormat), orderStage: orderItm.orders[0].orderStage, deliveryMethod: "DINE", orderTotal: parseFloat(orderItm.orders[0].orderTotal).toFixed(2), paymentStatus: orderItm.orders[0].paymentStatus, amountPaid: orderItm.orders[0].amountPaid });
                        return openOrderList;
                    }
                }
                return openOrderList;
            });
            setOrderListByType(openOrderList)
            handlePaginator(openOrderList, pageDetails.pageSize, 1)
        } else {
            const openOrderList = [];
            if (orderList.singleOrders) {
                await orderList.singleOrders.forEach(orderItm => {
                    if (orderItm.deliveryMethod === orderType) {
                        openOrderList.push({ ...orderItm, orderDate: standardDateFormat(orderItm.orderDate || '', dateTimeFormat), orderTotal: parseFloat(orderItm.orderTotal).toFixed(2), amountPaid: parseFloat(orderItm.amountPaid).toFixed(2) });
                    }
                })
            }
            setOrderListByType(openOrderList)
            handlePaginator(openOrderList, pageDetails.pageSize, currentPage);
        }
    };

    /**
     * function for filter records by current selected order type
     * @param {string} orderType 
     */
    const handleOrderTypeFilter = (orderType) => {
        setSearchFilter({ ...initalSearchFilterState });
        setPageDetails({ ...pageDetails, currentPage: 1 });
        setCurrentOrderType(orderType);
        sortOrdersByOrderType(cloneOrderList, orderType, 1)
        getOrderStagesByApi(orderType);
    };

    /**
     * create chunks function
     * @param {Array} orderList 
     * @param {number} pageSize 
     * @param {number} currentPage 
     */
    const handlePaginator = (orderList, pageSize, currentPage) => {
        const chunksRecord = [];
        orderList && orderList.forEach((item) => {
            if (!chunksRecord.length || chunksRecord[chunksRecord.length - 1].length === pageSize)
                chunksRecord.push([]);

            chunksRecord[chunksRecord.length - 1].push(item);
        });
        setOpenOrders(chunksRecord[currentPage - 1] === undefined ? [] : chunksRecord[currentPage - 1]);
    };

    /**
     * handle pagination for records.
     * @param {string} pageType 
     */
    const handlePagination = (pageType) => {
        if (pageType === "NEXT") {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage + 1 });
            handlePaginator(orderListByType, pageDetails.pageSize, pageDetails.currentPage + 1);
        } else {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage - 1 });
            handlePaginator(orderListByType, pageDetails.pageSize, pageDetails.currentPage - 1);
        }
    };

    /**
     * define page size with records
     * @param {event} event 
     */
    const handlePageSize = (event) => {
        setPageDetails({ ...pageDetails, pageSize: parseInt(event.target.value) });
        handlePaginator(orderListByType, parseInt(event.target.value), 1);
    };

    /**
     * common modal manage function 
     * @param {string} modalName 
     * @param {boolean} visible 
     */
    const handleModalManager = (modalName, visible) => {
        setModalManager({ ...modalManager, modalName, visible })
    };

    /**
     * call when order type change
     * @param {string} currentOrderType 
     */
    const onOrderTypeChange = (currentOrderType) => {
        setLoading(true)
        setCurrentOrderType(currentOrderType);
        getOpenOrderList(currentOrderType, pageDetails);
        setTimeout(() => {
            handleModalManager('', false);
        }, 500);
    };

    /**
     * function for close modal and fetch store data
     * @param {boolean} isDataChangeFlag 
     */
    const onHideModals = async (isDataChangeFlag) => {
        handleModalManager('', false);
        if (isDataChangeFlag) {
            setLoading(true);
            await getOpenOrderList(currentOrderType, pageDetails);
        }
    };

    /**
     * set updated customer details
     * @param {object} order 
     */
    const getCurrentSelOrder = (order) => {
        selectedOrder = { ...order };
    };

    /**
     * 
     * @param {event} event 
     */
    const searchRecordByToken = (event) => {
        const { value } = event.target;
        const emptyArr = [];
        cloneOrderList && cloneOrderList.tokenOrders.forEach(order => {
            if (String(order.tag).toLocaleLowerCase().includes(String(value).toLocaleLowerCase())) {
                emptyArr.push(order);
            }
        })
        let cloneOrders = deepCopy(cloneOrderList);
        cloneOrders.tokenOrders = emptyArr
        sortOrdersByOrderType(cloneOrders, currentOrderType, pageDetails.currentPage);
        setSearchFilter({ ...initalSearchFilterState, searchText: value })
    };

    const searchRecordByCustomerDetails = (event) => {
        const { value } = event.target;
        const emptyArr = [];
        cloneOrderList && cloneOrderList.singleOrders.forEach(order => {
            if (order.customer && (String(order.customer.name).toLocaleLowerCase().includes(value)) || (String(order.customer.mobileNumber).toLocaleLowerCase().includes(String(value).toLocaleLowerCase()))) {
                emptyArr.push(order);
            }
        })
        let cloneOrders = deepCopy(cloneOrderList);
        cloneOrders.singleOrders = emptyArr;
        sortOrdersByOrderType(cloneOrders, currentOrderType, pageDetails.currentPage);
        setSearchFilter({ ...initalSearchFilterState, searchText: value })
    };

    const filterRecordByOrderStage = (event) => {
        const { value } = event.target;
        const emptyArr = []
        cloneOrderList && cloneOrderList.singleOrders.forEach(orders => {
            if (String(orders.orderStage || "").toLocaleLowerCase().includes(String(value).toLocaleLowerCase())) {
                emptyArr.push(orders);
            }
        })
        let cloneOrders = deepCopy(cloneOrderList);
        cloneOrders.singleOrders = emptyArr;
        sortOrdersByOrderType(cloneOrders, currentOrderType, pageDetails.currentPage);
        setSearchFilter({ ...initalSearchFilterState, orderStage: value })
    }

    const clearSearch = () => {
        setSearchFilter({ ...initalSearchFilterState });
        sortOrdersByOrderType(cloneOrderList, currentOrderType, pageDetails.currentPage);
    };

    /**
     * 
     * @param {string} newStage 
     * @param {object} orderDetails 
     */
    const onOrderStageChange = async (flag) => {
        if (flag === 'YES') {
            if (selectedOrder) {
                await dispatch(updateOrder({ id: selectedOrder.id, orderStage: selectedOrder.newStage }))
                    .then((response) => {
                        if (response.payload && !response.payload.error) {
                            setLoading(true);
                            handleModalManager('', false);
                            setToastMessage({ visible: true, toastMessage: "Order stage updated.", variant: 'Success' });
                            setTimeout(() => {
                                getOpenOrderList(currentOrderType, pageDetails);
                            }, 500);
                        } else {
                            setToastMessage({ visible: true, toastMessage: "Error while updating order stage.", variant: 'Danger' });
                        }
                    })
            }
        } else {
            handleModalManager('', false);
        }
    }

    /**
     * define modal props
     */
    const tokenOrderDetailModalProps = { showModal: (modalManager.modalName === 'tokenOrderDetailModal' && modalManager.visible === true), hideModal: onHideModals, selectedOrder: selectedOrder, handleToastManager: setToastMessage, onOrderTypeChange: onOrderTypeChange };
    const singleOrderDetailModalProps = { showModal: (modalManager.modalName === 'singleOrderDetailModal' && modalManager.visible === true), hideModal: onHideModals, selectedOrder: selectedOrder, onOrderTypeChange: onOrderTypeChange, handleToastManager: setToastMessage };
    const openOrderTableProps = { currentOrderType: currentOrderType, openOrders: openOrders, loading: loading, handleModalManager: handleModalManager, setCurrentSelOrder: getCurrentSelOrder, orderStages: orderStages || [], onOrderStageChange: (orderDetails) => { handleModalManager('confirmModal', true); selectedOrder = orderDetails } };
    const confirmModalProps = { showModal: (modalManager.modalName === 'confirmModal' && modalManager.visible === true), hideModal: () => handleModalManager('', false), handleClick: onOrderStageChange, message: "Are you sure you want to change the order stage?" };
    const uploadTrackingNoModal = { showModal: (modalManager.modalName === 'uploadTrackingNoModal' && modalManager.visible === true), selectedOrder: selectedOrder, hideModal: onHideModals, handleClick: onOrderStageChange, handleToastManager: setToastMessage }

    return (
        <>
            <ToastManager toastManager={toastMessage} setToastManager={setToastMessage} />
            <Container fluid className='px-3'>
                <Row className="mt-4 g-0">
                    <Col md={12}>
                        <Row className="g-3">
                            <Col md={12} className=''>
                                <Nav className="card-tabs">
                                    {orderTypeMethods && orderTypeMethods.map((method, index) => (
                                        <Nav.Item key={index}>
                                            <Nav.Link active={currentOrderType === method.methodCode} onClick={() => handleOrderTypeFilter(method.methodCode)}>{method.name}</Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>

                            </Col>
                            <Col md={12} className=''>
                                <Row className="g-3">
                                    {currentOrderType === 'DINE' ?
                                        <Col md={6}>
                                            <Form.Floating className="search_bar">
                                                <span class="input-group-text search_icon" ><LuSearch /></span>
                                                <Form.Control className="search_input" value={searchFilter.searchText} type="text" autoComplete='off' name="searchText" placeholder="Token No" onChange={searchRecordByToken} />
                                                <Form.Label htmlFor="floatingInputCustom">Token No</Form.Label>
                                            </Form.Floating>
                                        </Col>
                                        :
                                        <Col md={6}>
                                            <Form.Floating className="search_bar">
                                                <span class="input-group-text search_icon" ><LuSearch /></span>
                                                <Form.Control className="search_input" value={searchFilter.searchText} type="text" autoComplete='off' name="searchText" placeholder="Search Customer Name / Mobile" onChange={searchRecordByCustomerDetails} />
                                                <Form.Label htmlFor="floatingInputCustom">Search Customer Name / Mobile</Form.Label>
                                            </Form.Floating>
                                        </Col>

                                    }
                                    <Col xs={6} sm={4} md={4} lg={2}>
                                        <Form.Floating>
                                            <Form.Select aria-label="Floating label select example" name="orderStage" onChange={filterRecordByOrderStage}>
                                                <option disabled={true} selected={searchFilter.orderStage === ''}>Select order stage</option>
                                                {orderStages.map((orderStage, indx) => {
                                                    return (
                                                        <option key={indx} value={orderStage.stageCode} selected={orderStage.stageCode === searchFilter.orderStage}>{orderStage.stageName}</option>
                                                    )
                                                })}

                                            </Form.Select>
                                            <Form.Label htmlFor="floatingInputCustom">Search by order stage</Form.Label>
                                        </Form.Floating>
                                    </Col>
                                    <Col xs={6} md={2}>
                                        <Button className='light-btn h-56 px-3' variant="primary" onClick={clearSearch} >
                                            <FaRotateLeft className='fs-16 me-2' />Clear
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>

                            <Col md={12}>
                                <OpenOrderTable {...openOrderTableProps} />
                                <Row className='g-0'>
                                    <Col md={12} className='table-pagination flex-wrap d-flex align-items-center justify-content-end'>
                                        <div className='d-flex align-items-center mb-3'>
                                            <span className='mx-3'> Items per page:</span>

                                            <select className='form-select' aria-label="Floating label select example" name="productType" onChange={handlePageSize}>
                                                {pageSizeSelectOptions.map((page, index) => {
                                                    return <option defaultValue={pageDetails.pageSize} key={index} value={page.value}>{page.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center mb-3'>
                                            <span className='mx-3'> {pageDetails.currentPage + " - " + Math.ceil(orderListByType?.length / pageDetails.pageSize) + " of " + orderListByType?.length}</span>
                                            <Button className='light-btn px-3' variant="primary" disabled={pageDetails.currentPage === 1 ? true : false} onClick={() => handlePagination('PREV')}><IoChevronBackOutline className='fs-16' /></Button>
                                            <Button className='light-btn px-3 ms-3' variant="primary" disabled={(orderListByType?.length < pageDetails.pageSize || pageDetails.currentPage === Math.ceil(orderListByType?.length / pageDetails.pageSize)) ? true : false} onClick={() => handlePagination('NEXT')} > <IoChevronForwardOutline className='fs-16' /></Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row >
            </Container >

            <ConfirmDialog {...confirmModalProps} />
            <TokenOrderDetailModal {...tokenOrderDetailModalProps} />
            <SingleOrderDetailModal {...singleOrderDetailModalProps} />
            <UploadTrackingNoModal {...uploadTrackingNoModal} />
        </>
    )
}

export default OpenOrders;