import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { DragDropContext } from "react-beautiful-dnd";
import Board from "../order-manage/widgets/board/Board";
import { useDispatch } from "react-redux";
// import { getBussinessStages, getKanbanOrders, updateBussinessOrder } from "../../actions/order/BoardActions";
// import { deepCopy } from "../../util/functions";
import './OrderManage.css';
import { useNavigate } from "react-router-dom";
import { getBussinessStages, getStageOrders, updateOrderStage } from "../../../actions/order/OrderManage";
import { deepCopy } from "../../../util/utility-helper";

let cloneBoardData = [];

const OrderManage = () => {
    const socketConnection = process.env.REACT_APP_SOCKET_ENDPOINT
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /**
     * board data with order store
     */
    const [boardData, setBoardData] = useState([]);

    const socket = new WebSocket(socketConnection);

    /**
     * web socket connection.
     */
    socket.onopen = () => {
        const user = localStorage.getItem("USER");
        if (user !== null && user !== "null") {
            socket.send(user);
        }
    };

    socket.onmessage = async () => {
        if (boardData) {
            await fetchOrderList(boardData);
        }
    };

    /**
     * call API first time to get stages and orders.
    */
    useEffect(() => {
        getBoardStages();
    }, ['']);

    /**
     * API for get stages and order lists.
     */
    const getBoardStages = async () => {
        await dispatch(getBussinessStages()).then(async (response) => {
            if (response.payload) {
                let stagesArr = response.payload || [];
                if (stagesArr) {
                    stagesArr = await stagesArr.stageTransitionSeq.sort((a, b) => { return a.stageOrder - b.stageOrder });
                    await fetchOrderList(stagesArr);
                }
            }
        });
    };

    /**
     * fetch order data.
     */
    const fetchOrderList = async (stagesArr) => {
        if (stagesArr) {
            let boardStages = stagesArr;
            await dispatch(getStageOrders()).then(async (response) => {
                const orders = response.payload || [];
                if (orders) {
                    if (boardStages) {
                        await boardStages.map(board => {
                            board = getCardByStageCode(board, orders);
                            return board;
                        });
                    }
                    console.log(boardStages)
                    cloneBoardData = boardStages;
                    setBoardData([...boardStages]);
                }
            });
        }
    };

    /**
     *  Function for check correct stage to move order.
     */
    const dragCardInBoard = async (source, destination) => {
        let boardtmpData = deepCopy(boardData);
        let cardDropData = null;
        const draggableIndex = boardtmpData.findIndex((item) => String(item.id) === String(source.droppableId));
        const droppableIndex = boardtmpData.findIndex((item) => String(item.id) === String(destination.droppableId));
        if (boardtmpData[draggableIndex] && boardtmpData[draggableIndex].transitions) {
            await boardtmpData[draggableIndex].transitions.forEach(transitions => {
                if (transitions === boardtmpData[droppableIndex].stageCode) {
                    cardDropData = { stageCode: boardtmpData[droppableIndex]?.stageCode, orderId: boardtmpData[draggableIndex].card[source.index]?.id };
                    boardtmpData[droppableIndex].card.splice(destination.index, 0, boardtmpData[draggableIndex].card[source.index]);
                    boardtmpData[draggableIndex].card.splice(source.index, 1);
                }
            })
        }
        return { data: boardtmpData, dropData: cardDropData };
    };

    /*
     * Function call when user successfuly end the drag.
    */
    const onDragEnd = async (args) => {
        const { source, destination } = args;
        if (!destination) {
            return
        }
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        };
        const newBoardData = await dragCardInBoard(source, destination);
        setBoardData(newBoardData.data);
        console.log(newBoardData)
        if (newBoardData.dropData !== null) {
            const payload = { orderId: newBoardData.dropData.orderId, newStage: newBoardData.dropData.stageCode };
            await dispatch(updateOrderStage(payload)).then((response) => {
                if (response.payload) {
                    fetchOrderList(boardData);
                }
            });
        }
    };

    /**
     * For full screen.
     */
    const showFullScreen = () => {
        document.getElementById('full-screen').requestFullscreen();
    };

    // get cards in board.
    const getCardByStageCode = (obj = {}, cardsData = []) => {
        const arr = [];
        cardsData.forEach(card => {
            if (card.orderStage === obj.stageCode) {
                arr.push(card);
            }
        });
        obj.card = arr;
        return obj;
    };

    /**
     * filter by order type.
     */
    const handleColumnByBadge = (orderType) => {
        // if (orderType !== "") {
        //     let newBoardData = deepCopy(boardData || []);
        //     if (newBoardData) {
        //         cloneBoardData.map((board, ind) => {
        //             if (board.card) {
        //                 const newCardList = board.card.filter((e) => e.deliveryMethod !== orderType);
        //                 newBoardData[ind].card = newCardList;
        //             }
        //             return board;
        //         })
        //     }
        //     setBoardData([...newBoardData]);
        // } else {
        //     setBoardData([...cloneBoardData]);
        // }
        // setSelectedBadge(orderType);
    };

    return (
        <>
            <Row className='g-0 bg-white'>
                <Col md={12} className="bg-white" id="full-screen" >
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className="App">
                            <div className="app_outer">
                                <div className="app_boards">
                                    {boardData.map((item) => (
                                        <Board
                                            boardName={item.stageName}
                                            key={item.id}
                                            id={item.id}
                                            card={item.card}
                                        />
                                    ))}

                                </div>
                            </div>
                        </div>
                    </DragDropContext>
                </Col>
            </Row >
        </>
    );
}

export default OrderManage;