import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateStore } from "../../../../../actions/admin/AdminActions";
import { getOrderTypeMethods, getPaymentMethods } from "../../../../../actions/billdesk/BillDeskActions";

const initalButtonState = { loading: false, disable: true };
const EditStoreModal = ({ showModal, hideModal, handleToastManager, selectedStore, fetchStoreList }) => {
    const dispatch = useDispatch();
    const [addStoreData, setAddStoreData] = useState({});
    const [buttonState, setButtonState] = useState({ ...initalButtonState });
    const [deliveryMethods, setDeliveryMethods] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);

    useEffect(() => {
        if (showModal) {
            setAddStoreData({ ...selectedStore })
            setButtonState({ ...initalButtonState });
            getDeliveryMethods();
            fetchPaymentMethods();
        }
    }, [showModal, selectedStore]);

    const getDeliveryMethods = async () => {
        await dispatch(getOrderTypeMethods())
            .then((response) => {
                if (response.payload) {
                    setDeliveryMethods(response.payload);
                }
            });
    };

    const fetchPaymentMethods = async () => {
        await dispatch(getPaymentMethods()).then((response) => {
            if (response.payload && !response.payload.error) {
                setPaymentMethods(response.payload);
            }
        })
    }

    const onSubmit = async () => {
        setButtonState({ ...buttonState, loading: true });
        await dispatch(updateStore({ ...addStoreData }))
            .then((response) => {
                if (response.payload && !response.payload.error) {
                    fetchStoreList();
                    hideModal(true);
                    handleToastManager({ visible: true, toastMessage: 'Store Updated.', variant: "Success" });
                } else {
                    handleToastManager({ visible: true, toastMessage: response.payload.error, variant: "Danger" });
                }
                setButtonState({ ...buttonState, loading: false });
            })
    };

    const handleChange = (event) => {
        setAddStoreData({ ...addStoreData, [event.target.name]: event.target.value });
        setButtonState({ ...buttonState, disable: false });
    };

    const handleMethodChange = (event) => {
        const { name, value } = event.target;
        let newStoreData = { ...addStoreData };
        if (String(newStoreData[name]).includes(value)) {
            newStoreData[name] = String(newStoreData[name]).replace(String(newStoreData[name]).includes(':' + value) ? ':' + value : value, '')
        } else {
            if (newStoreData[name] !== '') {
                newStoreData[name] = String(newStoreData[name]).concat(':' + value);
            } else {
                newStoreData[name] = value;
            }
        }
        setAddStoreData({ ...newStoreData })
        setButtonState({ ...buttonState, disable: false });
    };

    return (
        <Modal size="lg" centered backdrop="static" keyboard={false} show={showModal} onHide={hideModal}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Edit Store</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3" >
                    <Col md={4}>
                        <Form.Floating>
                            <Form.Control
                                type="text"
                                autoComplete="off"
                                name="name"
                                id="nameInput"
                                placeholder="Name"
                                value={addStoreData.name}
                                onChange={handleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Name<span className="text-danger">*</span></Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={4}>
                        <Form.Floating className="">
                            <Form.Control
                                type="text"
                                autoComplete="off"
                                name="storeKey"
                                id="storeKey"
                                placeholder="Store Key"
                                value={addStoreData.storeKey}
                                onChange={handleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Store Key</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={4}>
                        <Form.Floating>
                            <Form.Select
                                name="storeType"
                                id="storeType"
                                placeholder="Store Type"
                                value={addStoreData.storeType}
                                onChange={handleChange}
                                disabled={true}
                            >
                                <option value="" disabled >Select Status</option>
                                <option value="FINISHED" selected={addStoreData.storeType === 'FINISHED'}>FINISHED</option>
                                <option value="ASSEMBLY" selected={addStoreData.storeType === 'ASSEMBLY'}>ASSEMBLY</option>
                                <option value="ECOMMERCE" selected={addStoreData.storeType === 'ECOMMERCE'}>ECOMMERCE</option>
                            </Form.Select>
                            <Form.Label htmlFor="statusSelect">Store Type<span className="text-danger">*</span></Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={4}>
                        <Form.Floating className="">
                            <Form.Control
                                type="text"
                                autoComplete="off"
                                name="coverageRadius"
                                id="coverageRadius"
                                placeholder="CoverageRadius"
                                value={addStoreData.coverageRadius}
                                onChange={handleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Coverage Radius<span className="text-danger">*</span></Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={8}>
                        <Form.Floating className="">
                            <Form.Control
                                type="text"
                                autoComplete="off"
                                name="address"
                                id="Address"
                                placeholder="Address"
                                value={addStoreData.address}
                                onChange={handleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Address<span className="text-danger">*</span></Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={4}>
                        <Form.Floating className="">
                            <Form.Control
                                type="text"
                                autoComplete="off"
                                name="location"
                                id="Location"
                                placeholder="Location"
                                value={addStoreData.location}
                                onChange={handleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Location<span className="text-danger">*</span></Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={4}>
                        <Form.Floating className="">
                            <Form.Control
                                type="text"
                                autoComplete="off"
                                name="pinCode"
                                id="pinCode"
                                placeholder="pinCode"
                                value={addStoreData.pinCode}
                                onChange={handleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">PIN Code<span className="text-danger">*</span></Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={4}>
                        <Form.Floating className="">
                            <Form.Control
                                type="text"
                                autoComplete="off"
                                name="phoneNumber"
                                id="phoneNumber"
                                placeholder="PhoneNumber"
                                value={addStoreData.phoneNumber}
                                onChange={handleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Phone Number<span className="text-danger">*</span></Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={4}>
                        <Form.Label htmlFor="floatingInputCustom">Delivery Methods<span className="text-danger">*</span></Form.Label>
                        {deliveryMethods && deliveryMethods.map((methods, index) => {
                            return (
                                <Form.Check
                                    key={index}
                                    className=""
                                    type="checkbox"
                                    name="storeDeliveryMethods"
                                    id="storeDeliveryMethods"
                                    value={methods.methodCode}
                                    label={methods.name}
                                    checked={String(addStoreData.storeDeliveryMethods).includes(methods.methodCode)}
                                    onChange={handleMethodChange}
                                />
                            )
                        })}
                    </Col>
                    <Col md={4}>
                        <Form.Label htmlFor="floatingInputCustom">Payment Methods<span className="text-danger">*</span></Form.Label>
                        {paymentMethods && paymentMethods.map((methods, index) => {
                            return (
                                <Form.Check
                                    key={index}
                                    className=""
                                    type="checkbox"
                                    name="storePaymentMethods"
                                    id={methods.methodCode}
                                    value={methods.methodCode}
                                    label={methods.description}
                                    checked={String(addStoreData.storePaymentMethods).includes(methods.methodCode)}
                                    onChange={handleMethodChange}
                                />
                            )
                        })}
                    </Col>
                    <Col md={12}>
                        <Form.Floating className="">
                            <Form.Control
                                type="text"
                                autoComplete="off"
                                name="aboutStore"
                                id="aboutStore"
                                placeholder="AboutStore"
                                value={addStoreData.aboutStore}
                                onChange={handleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">About Store</Form.Label>
                        </Form.Floating>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="border-0 pt-0">
                <Row className="g-3 w-100 justify-content-end">
                    <Col md={3} lg={3} className="m-0">
                        {!buttonState.loading ?
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={onSubmit} disabled={buttonState.disable}>Save</Button>
                            : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    size="sm"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    )
}

export default EditStoreModal;