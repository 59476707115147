import React, { useState } from 'react';
import { Badge, Button, Col, Form, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { TbEdit } from 'react-icons/tb';
import { AiOutlineDelete } from 'react-icons/ai';
import { IoChevronBackOutline, IoChevronForwardOutline, } from 'react-icons/io5';
import { LuFilter, LuSearch } from 'react-icons/lu';
import { FaPlus } from 'react-icons/fa6';
import ToastManager from '../../../components/common/App/NotificationManager';
import { useDispatch } from 'react-redux';
import { deleteCategory } from '../../../actions/billdesk/BillDeskActions';
import CreatePurchaseOrder from './modals/CreatePurchaseOrder';
import EditPurchaseOrder from './modals/EditPurchaseOrder';

const pageSizeSelectOptions = [{ name: "10", value: 10 }, { name: "20", value: 20 }, { name: "50", value: 50 }];
const initialPageDetails = { currentPage: 1, pageSize: 10 };
const initialToastState = { visible: false, toastMessage: '', variant: "" };

const PurchaseOrder = () => {
    // useDispatch hook for dispatching actions
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [processMessage, setProcessMessage] = useState(false);

    // page handler store page size and page number
    const [pageDetails, setPageDetails] = useState({ ...initialPageDetails });
    const [categoryList, setCategoryList] = useState([]);

    // notification message
    const [toastMessage, setToastMessage] = useState({ ...initialToastState });

    const OrderDetails = [
        { date: '2024/08/07', POrder: 'PO-1234567', supp: 'supplier 1', items: '2', price: '00.00', status: 'PENDING' },
        { date: '2024/08/07', POrder: 'PO-1234567', supp: 'supplier 2', items: '2', price: '00.00', status: 'RECEIVED' },
        { date: '2024/08/07', POrder: 'PO-1234567', supp: 'supplier 3', items: '2', price: '00.00', status: 'CANCELLED' },
    ];

    const [modalManage, setModalManage] = useState({ modalName: '', visible: false });

    const handleModals = (modalName, isVisible) => {
        setModalManage({ ...modalManage, modalName: modalName, visible: isVisible });
    };

    const DeleteCategoryButton = async (item) => {
        const resp = await dispatch(deleteCategory({ ...item }));
        const response = resp?.meta?.requestStatus;
        if (response === "fulfilled") {
            handleToastManager({ visible: true, toastMessage: 'Product deleted successfully', variant: "Success" });
        }
        else {
            handleToastManager({ visible: true, toastMessage: 'Failed to delete product. Please try again.', variant: "Danger" });
        }
    };


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = OrderDetails.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // change page size
    const handlePageSize = (e) => {
    };

    // page handler
    const handlePaginaton = (page) => {
    };


    const onToastClose = () => {
        setToastMessage({ ...initialToastState });
    };

    const handleToastManager = (toastStatus) => {
        setToastMessage({ ...toastMessage, ...toastStatus });
    };

    const confirmOrderStatus = async (modalName, flag) => {
        if (flag) {
            switch (modalName) {
                case "create": {
                    handleModals('create', true);
                    break
                }
                case "edit": {
                    handleModals('edit', true);
                    break;
                }
                default: return
            }
        } else {
            handleModals(modalManage.modalName, false);
        }
    };

    return (
        <>

            {modalManage.modalName === "create" && modalManage.visible === true ?
                <CreatePurchaseOrder showModal={modalManage.modalName === "create"} hideModal={() => handleModals("create", false)} handleClick={confirmOrderStatus} handleToastManager={handleToastManager} />
                : modalManage.modalName === "edit" && modalManage.visible === true ?
                    <EditPurchaseOrder showModal={modalManage.modalName === "edit"} hideModal={() => handleModals("edit", false)} handleClick={confirmOrderStatus} handleToastManager={handleToastManager} />
                    : null}


            {/* Notification Manager */}
            <ToastManager toastManager={toastMessage} setToastManager={setToastMessage} />

            <Row className='g-0'>
                <Col sm={12} md={12} lg={12}>
                    <Row className='g-3'>
                        <Col md={12}>
                            <Row className='g-3'>
                                <Col md={6}>
                                    <Form.Floating className="search_bar">
                                        <span class="input-group-text search_icon" ><LuSearch /></span>
                                        <Form.Control className="search_input" id="productSearch" type="search" autoComplete='off' name="filterText" placeholder="Search By Terminal Name" />
                                        <Form.Label htmlFor="floatingInputCustom">Search by purchase order</Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={3} lg={3} xl={2} className=''>
                                    <Button className='light-btn h-56 w-100' variant="primary">
                                        <LuFilter className="me-1 fs-16" />
                                        Filter
                                    </Button>
                                </Col>
                                <Col md={3} lg={3} xl={2} className=''>
                                    <Button className='light-btn h-56 w-100' variant="primary" onClick={() => handleModals('create', true)}>
                                        <FaPlus className="me-1 fs-16" />
                                        Purchase Order
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Table responsive className='inso-table'>
                                <thead>
                                    <tr>
                                        <th className='text-center' width="15%">Date</th>
                                        <th className='text-center' width="20%">Purchase Order</th>
                                        <th className='text-center' width="15%">Supplier</th>
                                        <th className='text-center' width="15%">Items</th>
                                        {/* <th className='text-end' width="15%"><FaIndianRupeeSign />Price</th> */}
                                        <th className='text-center' width="15%">Status</th>
                                        <th className='text-center' width="15%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[...Array(10)].map((_, index) => {
                                        const absoluteIndex = indexOfFirstItem + index;
                                        const product = OrderDetails[absoluteIndex];
                                        if (!product) {
                                            return (
                                                <tr key={index}>
                                                    <td colSpan="12" className="text-center text-white">.</td>
                                                </tr>
                                            );
                                        }
                                        return (
                                            <>
                                                <tr key={absoluteIndex}>
                                                    <td className='text-center'>{product.date}</td>
                                                    <td className='text-center'>{product.POrder}</td>
                                                    <td className='text-center'>{product.supp}</td>
                                                    <td className='text-center'>{product.items}</td>
                                                    {/* <td className='text-end'>{product.price}</td> */}
                                                    <td className='text-center'>
                                                        {product.status === "PENDING" ?
                                                            <Badge bg="warning" className='text-dark'>Pending</Badge> :
                                                            product.status === "RECEIVED" ?
                                                                <Badge bg="success">Received</Badge> :
                                                                product.status === "CANCELLED" ?
                                                                    <Badge bg="danger">Cancelled</Badge> : null}
                                                    </td>
                                                    <td className='text-center'>
                                                        <OverlayTrigger placement='top' overlay={<Tooltip>Edit Purchase Order</Tooltip>}>
                                                            <Button
                                                                className="border-0 bg-transparent p-0 ms-3"
                                                                onClick={() => handleModals('edit', true)}
                                                            >
                                                                <TbEdit className="fs-16 text-dark " />
                                                            </Button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger placement='top' overlay={<Tooltip>Delete Purchase Order</Tooltip>}>
                                                            <Button className="border-0 bg-transparent p-0 ms-3"
                                                                onClick={() => DeleteCategoryButton()}
                                                            >
                                                                <AiOutlineDelete className="fs-16 text-dark " />
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <Row className='g-0'>
                                <Col md={12} className='table-pagination flex-wrap d-flex align-items-center justify-content-end'>
                                    <div className='d-flex align-items-center mb-3'>
                                        <span className='mx-3'> Items per page:</span>

                                        <select className='form-select' aria-label="Floating label select example" name="productType" onChange={(e) => { handlePageSize(e) }}>
                                            {pageSizeSelectOptions.map((page, index) => {
                                                return <option defaultValue={pageDetails.pageSize} key={index} value={page.value}>{page.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className='d-flex align-items-center mb-3'>
                                        <span className='mx-3'> {pageDetails.currentPage + " - " + Math.ceil(categoryList?.length / pageDetails.pageSize) + " of " + categoryList?.length}</span>
                                        <Button className='light-btn px-3' variant="primary" disabled={pageDetails.currentPage === 1 ? true : false} onClick={() => handlePaginaton('PREV')}><IoChevronBackOutline className='fs-16' /></Button>
                                        <Button className='light-btn px-3 ms-3' variant="primary" disabled={(categoryList?.length < pageDetails.pageSize || pageDetails.currentPage === Math.ceil(categoryList?.length / pageDetails.pageSize)) ? true : false} onClick={() => handlePaginaton('NEXT')}><IoChevronForwardOutline className='fs-16' /></Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>


        </>
    );
};

export default PurchaseOrder;
