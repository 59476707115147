import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { HEADERS } from "../../config/appHeaders";

const apiEndPoint = process.env.REACT_APP_API_BASE_ENDPOINT;

export const getBussinessStages = createAsyncThunk(
    'stages',
    async () => {
        const response = await axios.get(`${apiEndPoint}/business/store/stage-transitions`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const getStageOrders = createAsyncThunk(
    'stageOrder',
    async () => {
        const response = await axios.get(`${apiEndPoint}/operation/orders/kanban`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const updateOrderStage = createAsyncThunk(
    'updateOrderStage',
    async (payload) => {
        const response = await axios.put(`${apiEndPoint}/operation/order/${payload.orderId}/stage/${payload.newStage}`,payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)