import { createAsyncThunk } from "@reduxjs/toolkit";
import { HEADERS } from "../../config/appHeaders";
import axios from "axios";

const apiEndPoint = process.env.REACT_APP_API_BASE_ENDPOINT;
export const getCompleteOrders = createAsyncThunk(
    'completeOrders',
    async (payload) => {
        const response = await axios.post(`${apiEndPoint}/operation/orders/COMPLETED`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)


export const getOrderByInvNo = createAsyncThunk(
    'orderByInvNo',
    async (payload) => {
        const response = await axios.get(`${apiEndPoint}/operation/order/byInvoice/${payload}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)