import React from "react";
import imgOne from '../../../assets/images/mc-1.png';
import imgTwo from '../../../assets/images/mc-2.png';
import imgThree from '../../../assets/images/mc-3.png';
import imgFour from '../../../assets/images/mc-4.png';
import imgFive from '../../../assets/images/mc-5.png';
import imgSix from '../../../assets/images/mc-6.png';
import imgSeven from '../../../assets/images/mc-7.png';
import imgEight from '../../../assets/images/mc-8.png';
import { Image } from "react-bootstrap";

function BackFlash(props) {
    return (
        <div class="area" >
            <ul class="circles">
                <li className="circle-spin"><Image className='circle-img' src={imgOne} /></li>
                <li className="circle-spin"><Image className='circle-img' src={imgTwo} /></li>
                <li></li>
                <li className="circle-spin"><Image className='circle-img' src={imgThree} /></li>
                <li className="circle-spin"><Image className='circle-img' src={imgFour} /></li>
                <li className="circle-spin"><Image className='circle-img' src={imgFive} /></li>
                <li className="circle-spin"><Image className='circle-img' src={imgSix} /></li>
                <li className="circle-spin"><Image className='circle-img' src={imgSeven} /></li>
                <li></li>
                <li className="circle-spin"><Image className='circle-img' src={imgEight} /></li>
            </ul>
        </div>
    );
}

export default BackFlash;