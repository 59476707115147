import React from 'react';
import { Image } from 'react-bootstrap';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { BsBoxes } from 'react-icons/bs';
import { BiCategoryAlt } from 'react-icons/bi';
import { Link, useLocation } from 'react-router-dom';
import { LuClipboardList, LuHistory, LuUsers } from 'react-icons/lu';
import noImage from '../../assets/images/no_store_found.png';
import { MdOutlineHistoryEdu } from 'react-icons/md';
import logoIcon from '../../assets/images/Nlogo.jpeg';
import { IoMdImages } from 'react-icons/io';

const MainSidebar = ({ menuCollapse }) => {
    const location = useLocation();

    const businessName = process.env.REACT_APP_BUSINESS_NAME
    const businessLogo = process.env.REACT_APP_BUSSINESS_LOGO;

    const isActive = (path) => location.pathname === path;

    return (
        <>
            <div id="sidebar">
                <Sidebar collapsed={menuCollapse}>
                    <Menu className='sidebar_logo'>
                        <MenuItem className="main_logo">
                            <span className='logo_icon'>
                                <Image src={logoIcon ? logoIcon : noImage} />
                            </span>
                            {menuCollapse !== true && (<span className='logo_text'>{businessName}</span>)}
                        </MenuItem>
                    </Menu>
                    <Menu className='sidebar_content'

                    >
                        {/* <MenuItem icon={<BiServer className='fs-16' />}
                            active={isActive('/dashboard')}
                            component={<Link to="/dashboard" />}>Dashboard</MenuItem> */}
                        <MenuItem
                            icon={<LuUsers className='fs-16' />}
                            active={isActive('/admin')}
                            component={<Link to="/admin" />}
                        >
                            Administrator
                        </MenuItem>
                        <MenuItem
                            icon={<BsBoxes className='fs-16' />}
                            active={isActive('/products')}
                            component={<Link to="/products" />}
                        >
                            Products
                        </MenuItem>
                        <MenuItem
                            icon={<BiCategoryAlt className='fs-16' />}
                            active={isActive('/category')}
                            component={<Link to="/category" />}
                        >
                            Category
                        </MenuItem>
                        <MenuItem
                            icon={<LuClipboardList className='fs-16' />}
                            active={isActive('/inventory')}
                            component={<Link to="/inventory" />}
                        >
                            Inventory
                        </MenuItem>
                        <MenuItem
                            icon={<LuHistory className='fs-16' />}
                            active={isActive('/inventoryHistory')}
                            component={<Link to="/inventoryHistory" />}
                        >
                            Inventory History
                        </MenuItem>
                        <MenuItem
                            icon={<MdOutlineHistoryEdu className='fs-16' />}
                            active={isActive('/completeOrders')}
                            component={<Link to="/completeOrders" />}
                        >
                            Order History
                        </MenuItem>
                        <MenuItem
                            icon={<IoMdImages className='fs-16' />}
                            active={isActive('/resource')}
                            component={<Link to="/resource" />}
                        >
                            Ecommerce Media
                        </MenuItem>
                    </Menu>
                </Sidebar>
            </div >
        </>
    );
};

export default MainSidebar;
