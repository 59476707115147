import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

const InventoryUpdateActionModal = ({ ...props }) => {
    return (
        <Modal size="md" backdrop="static" keyboard={false} centered show={props.showModal} onHide={props.hideModal} >
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Choose Action</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3">
                    <Col md={12} className="text-center">
                        {/* {props.message} */}
                    </Col>
                </Row>
            </Modal.Body >
            <Modal.Footer className='border-0 pt-0'>
                <Row className='g-3 w-100 justify-content-center'>
                    <Col md={6} lg={6} >
                        <Button className='light-btn w-100 bg-light-grey' variant="primary" name="Save" onClick={() => props.handleClick('IMMEDIATE')}>Immediate Action</Button>
                    </Col>
                    <Col md={6} lg={6} >
                        <Button disabled className='light-btn w-100 bg-light-grey' variant="primary" name="Save" onClick={() => props.handleClick('PURCHASEORDER')}>Purchase Order</Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    )
}

export default InventoryUpdateActionModal;
