import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Dropdown, Form, Image, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { TbEdit } from 'react-icons/tb';
import { AiOutlineDelete } from 'react-icons/ai';
import { IoChevronBackOutline, IoChevronForwardOutline, IoPrintOutline } from 'react-icons/io5';
import { LuSearch } from 'react-icons/lu';
import ToastManager from '../../../components/common/App/NotificationManager';
import Qtylist from './widgets/Qtylist';
import { useDispatch } from 'react-redux';
import { deleteProductAssembly, getStoreInventoryList, searchStoreList } from '../../../actions/inventory/InventoryActions';
import loadingIcon from '../../../assets/images/loading-img.gif';
import { deepCopy, standardDateFormat } from '../../../util/utility-helper';
import PreviewModal from './modals/PreviewModal';
import ConfirmDialog from '../../../components/common/App/ConfirmDialog';
import InventoryUpdateActionModal from './modals/InventoryUpdateActionModal';
import EditAssemblyProductModal from './modals/EditAssemblyProductModal';
import { isAuthenticated } from '../../../config/appSession';
import { useNavigate } from 'react-router-dom';
import AddAssemblyproductDetailsModal from './modals/AddAssemblyProductModal';
import { FaPlus, FaSort } from 'react-icons/fa6';
import ProcessConfirmModal from './modals/ProcessConfirmModal';
import ModifyDetailModal from './modals/ModifyDetailsModal';
import AutoCompleter from '../../../components/common/App/AutoCompleter';
import noImage from '../../../assets/images/no-image-logo.png';

let selectedAssembly = null;
let selectedActionType = null;
let selectedStore = null;
let searchText = '';
let productListClone = []

const pageSizeSelectOptions = [{ name: "10", value: 10 }, { name: "20", value: 20 }, { name: "50", value: 50 }];
const initialPageDetails = { currentPage: 1, pageSize: 10 };
const initialModalManagerState = { modalName: "", visible: false };
const initialToastState = { visible: false, toastMessage: '', variant: "" };
const endPoint = process.env.REACT_APP_API_BASE_ENDPOINT;

const Inventory = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [pageDetails, setPageDetails] = useState({ ...initialPageDetails });
    const [toastMessage, setToastMessage] = useState({ ...initialToastState });
    const [loading, setLoading] = useState(false);
    const [productList, setProductList] = useState([]);

    const [storeInventoryList, setStoreInventoryList] = useState([]);
    const [inventoryDetailList, setInventoryDetailList] = useState([]);
    const [modalManager, setModalManager] = useState({ ...initialModalManagerState });
    const [storeList, setStoreList] = useState([]);
    const [sortBy, setSortBy] = useState('latest');

    useEffect(() => {
        checkAuthentication();
    }, ['']);

    const checkAuthentication = async () => {
        if (isAuthenticated()) {
            setLoading(true);
            const selStore = await getStoreList();;
            if (selStore) {
                selectedStore = selStore;
                await getStoreInventoryData(selStore.id);
            }
        } else {
            navigate('/user/login');
        }
    };

    const getStoreInventoryData = async (storeId) => {
        await dispatch(getStoreInventoryList(storeId))
            .then(async (response) => {
                if (response.payload) {
                    productListClone = response.payload;
                    setProductList(response.payload);
                    setInventoryDetailList([]);
                    const sortedList = await handleSort(sortBy, response.payload)
                    if (searchText !== '') {
                        handleSearch(searchText, sortedList)
                    } else {
                        handlePaginator(sortedList, pageDetails.pageSize, pageDetails.currentPage);
                    }
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                }
            })
    };

    const getStoreList = async () => {
        let apiResponse = [];
        await dispatch(searchStoreList({ 'searchFilter': '' })).then((response) => {
            if (response.payload) {
                setStoreList(response.payload)
                apiResponse = response.payload && response.payload.length > 0 ? response.payload[0] : ""
            }
        })
        return apiResponse
    }

    const handlePaginator = (orderList, pageSize, currentPage) => {
        const chunksRecord = [];
        orderList && orderList.forEach((item) => {
            if (!chunksRecord.length || chunksRecord[chunksRecord.length - 1].length === pageSize)
                chunksRecord.push([]);

            chunksRecord[chunksRecord.length - 1].push(item);
        });
        setStoreInventoryList([...chunksRecord[currentPage - 1] === undefined ? [] : chunksRecord[currentPage - 1]]);
    };

    const handlePageSize = (event) => {
        setPageDetails({ ...pageDetails, pageSize: parseInt(event.target.value), currentPage: 1 });
        handlePaginator(productList, parseInt(event.target.value), 1);
    };

    // page handler
    const handlePaginaton = (pageType) => {
        if (pageType === "NEXT") {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage + 1 });
            handlePaginator(productList, pageDetails.pageSize, pageDetails.currentPage + 1);
        } else {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage - 1 });
            handlePaginator(productList, pageDetails.pageSize, pageDetails.currentPage - 1);
        }
    };

    const adjustItemQuantity = (product, adjType) => {
        const foundIndex = inventoryDetailList.findIndex(e => e.productId === product.productId);
        let inventoryDetailLcl = [...inventoryDetailList];
        if (foundIndex > -1) {
            if (adjType === 'PLUS') {
                inventoryDetailLcl[foundIndex].newQty = (product.newQty + 1);
            } else {
                if (inventoryDetailLcl[foundIndex].newQty > 1) {
                    inventoryDetailLcl[foundIndex].newQty = (product.newQty - 1);
                } else {

                    handleStoreInventoryList(storeInventoryList.find(e => e.id === product.productId) || {});
                    inventoryDetailLcl.splice(foundIndex, 1);
                }
            }
        }
        setInventoryDetailList([...inventoryDetailLcl]);
    };

    const handleCheckbox = (event, product) => {
        selectedAssembly = product;
        const inventoryDetailListLcl = inventoryDetailList;
        if (event.target.checked) {
            if (inventoryDetailList.length > 0) {
                const isFound = inventoryDetailList.find(e => e.productId === product.id);
                if (!isFound) {
                    if (selectedActionType === 'IMMEDIATE') {
                        inventoryDetailListLcl.push({ productId: product?.id, prodAlias: product?.prodAlias, oldQty: product.qty, newQty: product.qty > 0 ? product.qty : 1, });
                    } else {
                        inventoryDetailListLcl.push({ productId: product.id, prodAlias: product?.prodAlias, oldQty: selectedAssembly?.qty, newQty: 0 });
                    }
                    setInventoryDetailList([...inventoryDetailListLcl]);
                    handleStoreInventoryList(product);
                }
            } else {
                handleModalManager('inventoryUpdateActionModal', true);
            }
        } else {
            const foundIndex = inventoryDetailListLcl.findIndex(e => e.productId === product.id);
            if (foundIndex > -1) {
                inventoryDetailListLcl.splice(foundIndex, 1);
                setInventoryDetailList([...inventoryDetailListLcl]);
                handleStoreInventoryList(product);
            }
        }
    };

    const handleStoreInventoryList = (product) => {
        const foundedIndex = storeInventoryList.findIndex(e => e.id === product.id);
        let storeInventoryLcl = [...storeInventoryList];
        if (storeInventoryList.length > 0 && foundedIndex > -1) {
            storeInventoryLcl[foundedIndex].checked = !storeInventoryLcl[foundedIndex].checked;
            setStoreInventoryList([...storeInventoryLcl]);
        }
    };

    const onConfirmOrder = (flag) => {
        if (flag === 'YES') {
            switch (modalManager.modalName) {
                case 'deleteConfirmModal': {
                    deleteProduct()
                    break;
                }
                case 'clearConfirmModal': {
                    selectedAssembly = null;
                    selectedActionType = null;
                    setLoading(true)
                    getStoreInventoryData(selectedStore?.id);
                    setInventoryDetailList([]);
                    handleModalManager('', false);
                    break;
                }
                default: break
            }
        } else {
            handleModalManager('', false)
        }
    };

    const deleteProduct = async () => {
        await dispatch(deleteProductAssembly({ ...selectedAssembly, storeId: selectedStore?.id }))
            .then((response) => {
                if ((response.payload && !response.payload.error)) {
                    setLoading(true);
                    getStoreInventoryData(selectedStore?.id);
                    handleModalManager('', false)
                    setToastMessage({ visible: true, toastMessage: 'Product Deleted.', variant: "Success" });
                } else {
                    setToastMessage({ visible: true, toastMessage: 'Erorr While Deleting Product.', variant: "Danger" });
                }
            })
    };

    const onInventoryUpdateActionSelect = (actionType) => {
        selectedActionType = actionType;
        const inventoryDetailListLcl = inventoryDetailList;
        if (actionType === 'IMMEDIATE') {
            inventoryDetailListLcl.push({ productId: selectedAssembly?.id, prodAlias: selectedAssembly?.prodAlias, oldQty: selectedAssembly.qty, newQty: selectedAssembly && (selectedAssembly.qty > 0 ? selectedAssembly.qty : 1) });
        } else {
            inventoryDetailListLcl.push({ productId: selectedAssembly?.id, prodAlias: selectedAssembly?.prodAlias, oldQty: selectedAssembly.qty, newQty: 0 });
        }
        handleModalManager('', false);
        setInventoryDetailList([...inventoryDetailListLcl]);
        handleStoreInventoryList(selectedAssembly);
    };

    const handleModalManager = (modalName, visible) => {
        setModalManager({ ...modalManager, modalName, visible });
    };

    const onHideModals = (isDataChangeFlag) => {
        if (isDataChangeFlag) {
            setLoading(true);
            setTimeout(() => {
                getStoreInventoryData(selectedStore?.id);
            }, 500);
        }
        handleModalManager('', false);
    };

    const handleSearch = (searchVal = '', products = []) => {
        const emptyArr = [];
        products.forEach(inventory => {
            if (inventory.prodAlias && String(inventory.prodAlias).toLowerCase().includes(String(searchVal.toLowerCase()))) {
                emptyArr.push(inventory);
            }
        })
        searchText = searchVal;
        setProductList(emptyArr)
        handlePaginator(emptyArr, pageDetails.pageSize, pageDetails.currentPage);
    };

    const handleSort = (sortBy, productList) => {
        setSortBy(sortBy);
        if (sortBy === 'latest') {
            return productList.sort((a, b) => { return parseInt(b.id) - parseInt(a.id) });
        } else {
            return productList.sort((a, b) => { return b.prodAlias.localeCompare(a.prodAlias) }).reverse();
        }
    };

    const setConfirmDialogMessage = () => {
        switch (modalManager.modalName) {
            case "deleteConfirmModal": {
                return "Are you sure you want to delete the order?";
            } case "clearConfirmModal": {
                return "Are you sure you want to clear the order?";
            }
            case "processConfirmModal": {
                return "Are you sure you want to create purchase order with present information?";
            }
            default: return
        }
    };

    const saveInventoryDetails = (inventoryDetails = []) => {
        setInventoryDetailList(inventoryDetails);
        inventoryDetailList.forEach(inv => {
            if (!(inventoryDetails.find(e => e.productId === inv.productId))) {
                handleStoreInventoryList(storeInventoryList.find(e => e.id === inv.productId) || {});
            }
        })
        handleModalManager('confirmModal', true);
    };

    const onProcessModalConfirm = (modalName, visible) => {
        handleModalManager(modalName, visible);
    };

    const refreshDataOnInventorySave = () => {
        selectedActionType = null;
        selectedAssembly = null;
        handleModalManager('', false);
        setInventoryDetailList([]);
        setLoading(true);
        getStoreInventoryData(selectedStore.id);
    };

    const handleStoreSelect = (selStore) => {
        if (selStore) {
            selectedStore = selStore.selItm;
            setLoading(true);
            getStoreInventoryData(selStore?.selItm?.id);
        };
    };

    const handleStoreSearch = async (value) => {
        await dispatch(searchStoreList({ 'searchFilter': value }))
            .then((res) => {
                if (res.payload) {
                    setStoreList(res.payload);
                }
            })
    };

    const inventoryActionUpdateModalProps = { showModal: (modalManager.modalName === "inventoryUpdateActionModal" && modalManager.visible === true), hideModal: () => handleModalManager('', false), handleClick: onInventoryUpdateActionSelect };
    const editProductModalProps = { showModal: (modalManager.modalName === 'editProductModal' && modalManager.visible === true), hideModal: onHideModals, selectedAssembly: selectedAssembly, handleToastManager: setToastMessage, storeId: selectedStore?.id };
    const previewModalProps = { showModal: (modalManager.modalName === 'confirmModal' && modalManager.visible === true), hideModal: () => handleModalManager('processConfirmModal', true), inventoryList: inventoryDetailList, handleToastManager: setToastMessage, hideModalOnSave: refreshDataOnInventorySave, storeId: selectedStore?.id };
    const modifyDetailsModalProps = { showModal: (modalManager.modalName === "modifyDetailsModal" && modalManager.visible === true), hideModal: () => handleModalManager('processConfirmModal', true), saveInventoryDetails: saveInventoryDetails, inventoryList: deepCopy([...inventoryDetailList]), handleToastManager: setToastMessage, };
    const addAssemProductModalProps = { showModal: (modalManager.modalName === "addAssemblyProductModal" && modalManager.visible === true), hideModal: onHideModals, handleToastManager: setToastMessage, selectedAssembly: selectedAssembly, storeId: selectedStore?.id };
    const confirmModalProps = { showModal: ((modalManager.modalName === 'deleteConfirmModal' || modalManager.modalName === 'clearConfirmModal') && modalManager.visible === true), message: setConfirmDialogMessage(), hideModal: () => handleModalManager('', false), handleClick: onConfirmOrder };
    const processConfirmModalProps = { showModal: (modalManager.modalName === 'processConfirmModal' && modalManager.visible === true), hideModal: () => handleModalManager('', false), message: setConfirmDialogMessage(), handleClick: onProcessModalConfirm };

    return (
        <>
            <ToastManager toastManager={toastMessage} setToastManager={setToastMessage} />
            <Row className='g-0'>
                <Col sm={12} md={12} lg={12}>
                    <Row className='g-3'>
                        <Col md={12}>
                            <Row className='g-3'>
                                <Col md={12}>
                                    <Row className='g-3'>
                                        <Col md={6}>
                                            <AutoCompleter name="name" type="autoComplete" data={storeList} onChange={handleStoreSelect} placeholder="Select Store" onSearch={handleStoreSearch} preSelectedIndex={0} extraLabel="storeType" />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Form.Floating className="search_bar">
                                        <span class="input-group-text search_icon" ><LuSearch /></span>
                                        <Form.Control className="search_input" id="productSearch" type="search" autoComplete='off' name="filterText" placeholder="Search by product name" onChange={(event) => handleSearch(event.target.value, productListClone)} />
                                        <Form.Label htmlFor="floatingInputCustom">Search by Product Name</Form.Label>
                                    </Form.Floating>
                                </Col>
                                {/* <Col md={3} lg={3} xl={2} className=''>
                                    <Button className='light-btn h-56 w-100' variant="primary" onClick={() => handleModalManager('addAssemblyProductModal', true)}>
                                        <FaPlus className="me-1 fs-16" />
                                        Inventory
                                    </Button>
                                </Col> */}
                                <Col md={6} lg={6} xl={6}>
                                    <Row className='g-3'>
                                        <Col md={6} lg={6} xl={4} className=''>
                                            <Dropdown className=''>
                                                <Dropdown.Toggle className='light-btn h-56 w-100 justify-content-between px-3' id="dropdown-basic">
                                                    <span>
                                                        <FaSort className="me-1 fs-16" />Sort
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handlePaginator(handleSort('latest', productListClone), pageDetails.pageSize, pageDetails.currentPage)} active={sortBy === 'latest'}>Latest</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handlePaginator(handleSort('name', productListClone), pageDetails.pageSize, pageDetails.currentPage)} active={sortBy === 'name'}>By Name</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </Col>
                                        <Col md={6} lg={6} xl={4} className='ms-auto'>
                                            <Button className='light-btn h-56 w-100 bg-light-grey' variant="primary">
                                                <IoPrintOutline className="me-1 fs-16" />
                                                Print
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row className='g-3'>
                                <Col md={9} className='left-side'>
                                    <Table responsive className='inso-table'>
                                        <thead>
                                            <tr>
                                                <th className='text-center' width="7%"></th>
                                                <th className='text-start' width="20%">Product Name</th>
                                                <th className='text-center' width="15%">Qty</th>
                                                <th className='text-center' width="15%">Warning Limit</th>
                                                <th className='text-center' width="15%">Critical Limit</th>
                                                <th className='text-center' width="15%">Last Date</th>
                                                <th className='text-center' width="15%">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!loading ?
                                                storeInventoryList.length > 0 ?
                                                    storeInventoryList.map((product, index) => {
                                                        return (
                                                            <>
                                                                <tr key={index}>
                                                                    <td className='text-center '>
                                                                        {product.isStockManaged === "N" ?
                                                                            <OverlayTrigger placement='top' overlay={<Tooltip>Add Inventory</Tooltip>}>
                                                                                <Button className="border-0 bg-transparent p-0" onClick={() => { handleModalManager('addAssemblyProductModal', true); selectedAssembly = product }}>
                                                                                    <FaPlus className="fs-16 text-dark " />
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                            :
                                                                            <Form.Check className='' checked={product.checked || false} type='checkbox' onChange={(event) => handleCheckbox(event, product)} />
                                                                        }
                                                                    </td>
                                                                    <td className='text-start'>
                                                                        <div className='d-flex align-items-center'>
                                                                            <Image width='30px' height='30px' className='me-3 object-fit-cover' style={{ borderRadius: '10px' }} src={product.icon ? endPoint + product.icon : noImage} />
                                                                            <span> {product.prodAlias && product.prodAlias}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td className='text-center'>{product.qty || "-"}</td>
                                                                    <td className='text-center'>{product.warningLimit || "-"}</td>
                                                                    <td className='text-center'>{product.criticalLimit || "-"}</td>
                                                                    <td className='text-center'>{product.lastDate ? standardDateFormat(product.lastDate) : "-"}</td>
                                                                    <td className='text-center'>
                                                                        <OverlayTrigger placement='top' overlay={<Tooltip>Edit Product</Tooltip>}>
                                                                            <Button className="border-0 bg-transparent p-0" onClick={() => { handleModalManager('editProductModal', true); selectedAssembly = product }}>
                                                                                <TbEdit className="fs-16 text-dark " />
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                        <OverlayTrigger placement='top' overlay={<Tooltip>Delete Product</Tooltip>}>
                                                                            <Button className="border-0 bg-transparent p-0 ms-3" onClick={() => { handleModalManager("deleteConfirmModal", true); selectedAssembly = product }}>
                                                                                <AiOutlineDelete className="fs-16 text-dark " />
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    </td>
                                                                </tr >
                                                            </>
                                                        )
                                                    })
                                                    : <tr>
                                                        <td colSpan="12" className="text-center">No products available</td>
                                                    </tr>
                                                : <tr  >
                                                    <td colSpan='12'>
                                                        <div className="d-flex align-items-center justify-content-center" style={{ height: '508px' }} >
                                                            <Image className='loading-img' src={loadingIcon} alt="logo" />
                                                        </div></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                    <Row className='g-0'>
                                        <Col md={12} className='table-pagination flex-wrap d-flex align-items-center justify-content-end'>
                                            <div className='d-flex align-items-center mb-3'>
                                                <span className='mx-3'> Items per page:</span>

                                                <select className='form-select' aria-label="Floating label select example" name="productType" onChange={(e) => { handlePageSize(e) }}>
                                                    {pageSizeSelectOptions.map((page, index) => {
                                                        return <option defaultValue={pageDetails.pageSize} key={index} value={page.value}>{page.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className='d-flex align-items-center mb-3'>
                                                <span className='mx-3'> {pageDetails.currentPage + " - " + Math.ceil(productList?.length / pageDetails.pageSize) + " of " + productList?.length}</span>
                                                <Button className='light-btn px-3' variant="primary" disabled={pageDetails.currentPage === 1 ? true : false} onClick={() => handlePaginaton('PREV')}><IoChevronBackOutline className='fs-16' /></Button>
                                                <Button className='light-btn px-3 ms-3' variant="primary" disabled={(productList?.length < pageDetails.pageSize || pageDetails.currentPage === Math.ceil(productList?.length / pageDetails.pageSize)) ? true : false} onClick={() => handlePaginaton('NEXT')}><IoChevronForwardOutline className='fs-16' /></Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    <Card className='right-side'>
                                        <Card.Header className='pb-0 bg-transparent border-0'>
                                            <Row className="g-3">
                                                <Col md={12}>
                                                    <h4 className="fs-16">Qty</h4>
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row className="g-0 ">
                                                <Col md={12}>
                                                    <Qtylist items={inventoryDetailList} qtyManage={adjustItemQuantity} changeQtyInput={() => { }} />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                        <Card.Footer className='bg-white border-0'>
                                            <hr />
                                            <Row className="g-3">
                                                <Col md={6} className=''>
                                                    <Button className='light-btn w-100 text-truncate h-56' variant="primary" onClick={() => handleModalManager('processConfirmModal', true)} disabled={inventoryDetailList.length === 0}>Process</Button>
                                                </Col>
                                                <Col md={6} className=''>
                                                    <Button className='light-btn w-100 text-truncate h-56 bg-light-grey' variant="primary" onClick={() => handleModalManager('clearConfirmModal', true)} disabled={inventoryDetailList.length === 0}>Clear</Button>
                                                </Col>
                                            </Row>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col >
            </Row >
            <ModifyDetailModal {...modifyDetailsModalProps} />
            <ProcessConfirmModal {...processConfirmModalProps} />
            <AddAssemblyproductDetailsModal {...addAssemProductModalProps} />
            <InventoryUpdateActionModal {...inventoryActionUpdateModalProps} />
            <EditAssemblyProductModal  {...editProductModalProps} />
            <PreviewModal {...previewModalProps} />
            <ConfirmDialog {...confirmModalProps} />
        </>
    );
};

export default Inventory;
