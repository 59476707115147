import React from "react";
import Card from "../card/Card";
import "./Board.css";
import { Droppable } from "react-beautiful-dnd";

function Board(props) {

  return (
    <div className="board">
      <div className="board__top">
        <div>
          <p className="board__title">
            {props?.boardName || "Name of Board"}
            <span className="total__cards">{props?.card?.length}</span>
          </p>
        </div>
      </div>
      <Droppable droppableId={props?.id?.toString()}>
        {(provided) => (
          <div
            className="board__cards"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {props.card?.map((items, index) => (
              <Card
                bid={props?.id}
                id={items?.id}
                index={index}
                key={items?.id}
                title={items?.customer ? items?.customer?.name : ""}
                card={items}
                deliveryMethod={items?.deliveryMethod}
                tempInvoiceNo={items?.tempInvoiceNo}
                paymentStatus={items?.paymentStatus}
                paymentMethod={items?.paymentMethod}
                itemCount={items?.itemCount}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

    </div>
  );
}

export default Board