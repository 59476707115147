import React from "react";
import { Image } from "react-bootstrap";
import gif from '../../../assets/images/loading-img.gif';

function Loader({ loaderState }) {

    return (
        <>
            <div className="spinner-img" hidden={!loaderState}>
                <Image width='200px' className='' src={gif} alt="logo" />
            </div>
        </>
    )
}

export default Loader;