import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, } from 'react-bootstrap';
import NumberField from '../../../../../../components/common/App/NumberField';
import { FaCircleInfo } from 'react-icons/fa6';

const PaymentType = ({ ...props }) => {
    const [orderDetails, setOrderDetails] = useState({});

    useEffect(() => {
        if (props.showModal) {
            setOrderDetails({ ...props.orderDetails, amountPaid: props.orderDetails.orderTotal });
        }
    }, [props.showModal, props.orderDetails]);

    const toggleChecked = () => {
        if (props.allowPartialPayment) {
            setOrderDetails({ ...orderDetails, paymentStatus: orderDetails.paymentStatus !== 'PARTIAL' ? 'PARTIAL' : 'FULL' });
        }
    };

    const onPay = () => {
        props.onPaymentDone({ ...orderDetails, paymentStatus: orderDetails.paymentStatus !== "PARTIAL" ? "PAID" : "PARTIAL", });
        props.hideModal();
    };

    const onPayLater = () => {
        props.onPaymentDone({ ...orderDetails, paymentStatus: "UNPAID", amountPaid: 0 });
        props.hideModal();
    };

    const handleChange = (event) => {
        setOrderDetails({ ...orderDetails, [event.target.name]: event.target.value });
    };

    const handlePaymentMethod = (paymentMethod) => {
        setOrderDetails({ ...orderDetails, paymentMethod })
    };

    return (
        <>

            <Modal size='lg' backdrop="static" keyboard={false} centered show={props.showModal} onHide={props.hideModal}  >
                <Modal.Header className='border-0 pb-0' closeButton>
                    <Modal.Title className='fs-5 fw-bold'>Payment Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='g-0'>
                        <Col md={8} className='mx-auto'>
                            <Row className='g-3'>
                                <Col xs={12}>
                                    <Row className='g-3'>
                                        <Col md={4}>
                                            <Button className={`light-btn w-100 text-truncate h-56 ${orderDetails.paymentMethod === "CASH" || orderDetails.paymentMethod === "COR" ? "bg-light-yellow" : "text-secondary"}`} variant="primary" onClick={() => handlePaymentMethod("CASH")} >
                                                Cash
                                            </Button>
                                        </Col>
                                        <Col md={4}>
                                            <Button className={`light-btn w-100 text-truncate h-56 ${orderDetails.paymentMethod === "UPI" ? "bg-light-yellow" : "text-secondary"}`} variant="primary" onClick={() => handlePaymentMethod("UPI")} >
                                                UPI
                                            </Button>
                                        </Col>
                                        <Col md={4}>
                                            <Button className={`light-btn w-100 text-truncate h-56 ${orderDetails.paymentMethod === "CARD" ? "bg-light-yellow" : "text-secondary"}`} variant="primary" onClick={() => handlePaymentMethod("CARD")}>
                                                Card
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                {orderDetails.paymentMethod === "CASH" || orderDetails.paymentMethod === "COR" ?
                                    <Col xs={7} className='mx-auto mt-4'>
                                        <Row className='g-3'>
                                            <Col md={5} className='d-flex align-items-center'>
                                                <div class="form-check form-switch text-start">
                                                    <input class="form-check-input" type="checkbox" role="switch" id="paymentType" checked={(orderDetails.paymentStatus === 'PARTIAL')} onChange={toggleChecked} />
                                                    <label class="form-check-label" for="paymentType">{orderDetails.paymentStatus === 'PARTIAL' ? 'Partial' : 'Full'}</label>
                                                </div>
                                            </Col>
                                            <Col md={7}>
                                                <NumberField classNam='form-control' style={{ height: '56px' }} autoComplete='off' disabled={!(orderDetails.paymentStatus === 'PARTIAL')} name="amountPaid" id="floatingInputCustom" placeholder="" value={(orderDetails.paymentStatus === 'PARTIAL') ? orderDetails.amountPaid : orderDetails.orderTotal} onChange={handleChange} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    : orderDetails.paymentMethod === "UPI" ?
                                        <Col xs={7} className='mx-auto mt-4'>
                                            <Row className='g-3'>
                                                <Col md={4} className='mb-3'>
                                                    <Form.Check checked type='radio' label='GPay' />
                                                </Col>
                                                <Col md={4} className='mb-3'>
                                                    <Form.Check type='radio' label='Paytm' />
                                                </Col>
                                                <Col md={4} className='mb-3'>
                                                    <Form.Check type='radio' label='PhonePe' />
                                                </Col>
                                            </Row>
                                        </Col>
                                        : orderDetails.paymentMethod === "CARD" ?
                                            <Col xs={12} className=' mt-4'>
                                                <Row className='g-3'>
                                                    <Col md={6}>
                                                        <Form.Floating className="">
                                                            <Form.Control className='text-capitalize' type="text" autoComplete='off' name="displayOrder" id="floatingInputCustom" placeholder="Product Name" value='' defaultValue='' onChange={(e) => { handleChange(e) }} />
                                                            <Form.Label htmlFor="floatingInputCustom">Card Number<span className='fw-bold'> * </span></Form.Label>
                                                        </Form.Floating>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Floating className="">
                                                            <Form.Control className='text-capitalize' type="text" autoComplete='off' name="displayOrder" id="floatingInputCustom" placeholder="Product Name" value='' defaultValue='' onChange={(e) => { handleChange(e) }} />
                                                            <Form.Label htmlFor="floatingInputCustom">Cardholder<span className='fw-bold'> * </span></Form.Label>
                                                        </Form.Floating>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Label htmlFor="floatingInputCustom">Expiry Date<span className='fw-bold'> * </span></Form.Label>
                                                        <Row className='g-3'>
                                                            <Col md={3}>
                                                                <Form.Floating controlId="floatingSelect">
                                                                    <Form.Select aria-label="Floating label select example" name="subCat" onChange={handleChange}>
                                                                        <option></option>
                                                                        <option>1</option>
                                                                        <option>2</option>
                                                                        <option>3</option>
                                                                        <option>4</option>
                                                                    </Form.Select>
                                                                    <Form.Label htmlFor="floatingInputCustom">Month</Form.Label>
                                                                </Form.Floating>
                                                            </Col>
                                                            <Col md={3}>
                                                                <Form.Floating controlId="floatingSelect">
                                                                    <Form.Select aria-label="Floating label select example" name="subCat" onChange={handleChange}>
                                                                        <option></option>
                                                                        <option>1</option>
                                                                        <option>2</option>
                                                                        <option>3</option>
                                                                        <option>4</option>
                                                                    </Form.Select>
                                                                    <Form.Label htmlFor="floatingInputCustom">Year</Form.Label>
                                                                </Form.Floating>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Floating className="input_info_icon">
                                                                    {/* <span className=''> */}
                                                                    <Form.Control className='text-capitalize' type="text" autoComplete='off' name="displayOrder" id="floatingInputCustom" placeholder="Product Name" value='' defaultValue='' onChange={(e) => { handleChange(e) }} />
                                                                    <FaCircleInfo className='info_icon' />
                                                                    {/* </span> */}
                                                                    <Form.Label htmlFor="floatingInputCustom">CVV</Form.Label>
                                                                </Form.Floating>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                </Row>
                                            </Col>
                                            : null}
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body >
                <Modal.Footer className='border-0 pt-0'>
                    <Row className="g-3 justify-content-end w-100">
                        <Col md={3} lg={2} className='m-0'>
                            <Button className='light-btn w-100 bg-light-green text-white' variant="primary" onClick={onPayLater} >
                                Pay Later
                            </Button>
                        </Col>
                        <Col md={3} lg={2} className='m-0'>
                            <Button className='light-btn w-100 bg-light-green text-white' variant="primary" onClick={onPay} >
                                Pay
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal >
        </>
    )

}

export default (PaymentType);