import React from "react";
import { Table } from 'react-bootstrap';
import { FaIndianRupeeSign, FaMinus, FaPlus, FaTrash } from "react-icons/fa6";

const Cart = ({ items, qtyManage, changeQtyManual }) => {
    return (
        <Table responsive className="cart-table" >
            <thead className=" ">
                <tr>
                    <th className="text-start">Item Name</th>
                    <th className="text-center ">Qty</th>
                    <th className="text-end "><FaIndianRupeeSign className='' /> Price</th>
                    <th className="text-end ">S-Total</th>
                </tr>
            </thead>
            <tbody className="">
                {items.length !== 0 ?
                    items.map((orderItem, key) => {
                        let itemPrice = orderItem.price;
                        if (orderItem.isPriceGstInc === 'Y') {
                            let itemGst = orderItem.perGst ? (itemPrice * orderItem.perGst / 100) : 0;
                            itemGst = itemGst.toFixed(2);
                            itemPrice = itemPrice - itemGst;
                        }
                        return (
                            <tr key={key}>
                                <td className="orderItem_name">{orderItem.name}</td>
                                <td className="">
                                    <span className="orderItem_qty">
                                        {orderItem.qty > 1 ?
                                            <span class="action-plus" onClick={() => qtyManage(orderItem, 'MINUS')} role="button"> <FaMinus /></span> :
                                            <span class="action-del" onClick={() => qtyManage(orderItem, 'MINUS')} role="button">
                                                <FaTrash />
                                            </span>}
                                        <input className="qtyInput" type="text" onClick={() => changeQtyManual({ ...orderItem })} value={orderItem.qty} />
                                        <span class="action-plus" onClick={() => qtyManage(orderItem, 'PLUS')} role="button"><FaPlus /></span>
                                    </span>
                                </td>
                                <td className="text-end">{parseFloat(itemPrice).toFixed(2)}</td>
                                <td className="text-end">{parseFloat(itemPrice * orderItem.qty).toFixed(2)}</td>
                            </tr>
                        )
                    })
                    :
                    null
                }
            </tbody>
        </Table>
    )
}

export default Cart;