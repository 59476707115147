import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Image, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import loadingIcon from '../../../assets/images/loading-img.gif';
import { isAuthenticated } from '../../../config/appSession';
import { useNavigate } from 'react-router-dom';
import { getInventoryHistoryList } from '../../../actions/inventory/InventoryHistoryActions';
import { standardDateFormat } from '../../../util/utility-helper'

const pageSizeSelectOptions = [{ name: "10", value: 10 }, { name: "20", value: 20 }, { name: "50", value: 50 }];
const initialPageDetails = { currentPage: 1, pageSize: 10 };
const initialDateFilters = { startDate: '', endDate: '' };

const dateFormat = process.env.REACT_APP_DATE_FORMAT;
const InventoryHistory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [pageDetails, setPageDetails] = useState({ ...initialPageDetails });
    const [loading, setLoading] = useState(false);

    const [inventoryHistoryList, setInventoryHistoryList] = useState([]);
    const [inventoryListToDisplay, setInventoryListToDisplay] = useState([]);
    const [dateFilters, setDateFilters] = useState({ ...initialDateFilters });
    const [totalInvList, setTotoalInvList] = useState(0)

    useEffect(() => {
        checkAuthentication();
    }, ['']);

    const checkAuthentication = () => {
        if (isAuthenticated()) {
            setDateFilters({ ...dateFilters, startDate: standardDateFormat(new Date(), dateFormat), endDate: standardDateFormat(new Date(), dateFormat) });
            fetchInventoryHistoryListByAPI({ startDate: standardDateFormat(new Date(), dateFormat), endDate: standardDateFormat(new Date(), dateFormat) });
        } else {
            navigate('/user/login');
        }
    };

    const fetchInventoryHistoryListByAPI = async (dateFilter) => {
        setLoading(true);
        await dispatch(getInventoryHistoryList({
            ...dateFilter,
        }))
            .then((response) => {
                if (response.payload) {
                    setLoading(false);
                    setInventoryHistoryList(response.payload)
                    handlePaginator(response.payload, pageDetails.pageSize, pageDetails.currentPage);
                }
            })
    };

    const handlePaginator = (invHistoryList, pageSize, currentPage) => {
        const groupIds = Object.keys(invHistoryList);
        let chunksRecord = [];
        const indexOfLastItem = currentPage * pageSize;
        const indexOfFirstItem = indexOfLastItem - pageSize;
        let totalRecords = 0;
        for (let i = 0; i < groupIds.length; i++) {
            for (let j = 0; j < invHistoryList[groupIds[i]].length; j++) {
                if (totalRecords >= indexOfFirstItem && totalRecords < indexOfLastItem) {
                    let foundIndex = null;
                    if ((foundIndex = chunksRecord.findIndex(e => e.groupId === invHistoryList[groupIds[i]][j].groupId)) > -1) {
                        chunksRecord[foundIndex].history.push(invHistoryList[groupIds[i]][j])
                    } else {
                        chunksRecord.push({ 'groupId': invHistoryList[groupIds[i]][j].groupId, 'date': invHistoryList[groupIds[i]][j].date, history: [invHistoryList[groupIds[i]][j]] });
                    }
                } else {
                    if (totalRecords === indexOfLastItem) {
                        break;
                    }
                }
                totalRecords = totalRecords + 1;
            }
        }
        countTotalInvList(invHistoryList, groupIds);
        setInventoryListToDisplay(chunksRecord);
    };

    const countTotalInvList = (invHistoryList = [], groupIds = []) => {
        let totalCount = 0
        for (let i = 0; i < groupIds.length; i++) {
            for (let j = 0; j < invHistoryList[groupIds[i]].length; j++) {
                totalCount = totalCount + 1;
            }
        }
        setTotoalInvList(totalCount)
    }

    const handlePageSize = (event) => {
        setPageDetails({ ...pageDetails, pageSize: parseInt(event.target.value), currentPage: 1 });
        handlePaginator(inventoryHistoryList, parseInt(event.target.value), 1);
    };

    // page handler
    const handlePaginaton = (pageType) => {
        if (pageType === "NEXT") {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage + 1 });
            handlePaginator(inventoryHistoryList, pageDetails.pageSize, pageDetails.currentPage + 1);
        } else {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage - 1 });
            handlePaginator(inventoryHistoryList, pageDetails.pageSize, pageDetails.currentPage - 1);
        }
    };

    const handleDateFilter = (event) => {
        setDateFilters({ ...dateFilters, [event.target.name]: event.target.value ? event.target.value : "" });
        fetchInventoryHistoryListByAPI({ ...dateFilters, [event.target.name]: standardDateFormat(event.target.value ? event.target.value : new Date(), dateFormat) });
    };

    return (
        <>
            <Row className='g-0'>
                <Col sm={12} md={12} lg={12}>
                    <Row className='g-3'>
                        <Col md={12}>
                            <Row className='g-3'>
                                <Col md={3} lg={3} xl={2} className='ms-auto'>
                                    <Form.Floating>
                                        <Form.Control className='shadow-none border' id="floatingInputCustom" type="date" autoComplete='off' name="startDate" value={standardDateFormat(dateFilters.startDate, dateFormat)} max={standardDateFormat(dateFilters.endDate, dateFormat)} placeholder="Start Date" onChange={handleDateFilter} />
                                        <Form.Label htmlFor="floatingInputCustom">Start Date</Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={3} lg={3} xl={2}>
                                    <Form.Floating>
                                        <Form.Control className='shadow-none border' id="floatingInputCustom" type="date" autoComplete='off' name="endDate" value={standardDateFormat(dateFilters.endDate, dateFormat)} min={standardDateFormat(dateFilters.startDate,dateFormat)} placeholder="End Date" onChange={handleDateFilter} />
                                        <Form.Label htmlFor="floatingInputCustom">End Date</Form.Label>
                                    </Form.Floating>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            {!loading ?
                                <Table responsive className='inso-table '>
                                    <tbody>

                                        {inventoryListToDisplay.length > 0 ? (
                                            inventoryListToDisplay.map((product, index) => {
                                                return (
                                                    <>
                                                        <tr key={index} className=''>
                                                            <td className='text-start bg-light-grey' width="40%"><span className='me-2'>Group Id:</span>{product.groupId}</td>
                                                            <td className='text-start bg-light-grey' width="60%"><span className='me-2'>Date</span>{standardDateFormat(product.date, dateFormat)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan='12' className='p-2'>
                                                                <Table responsive className='inso-table mb-0'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th className='text-start bg-white' width="20%">Product Name</th>
                                                                            <th className='text-center bg-white' width="15%">Orig Qty</th>
                                                                            <th className='text-center bg-white' width="15%">Updated Qty</th>
                                                                            <th className='text-center bg-white' width="15%">Action</th>
                                                                            <th className='text-start bg-white' width="20%">Comments</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {product.history.map((item, index) => (
                                                                            <>
                                                                                <tr key={index}>
                                                                                    <td className='text-start'>{item.business_product && item.business_product.prodAlias}</td>
                                                                                    <td className='text-center'>{item.origQty}</td>
                                                                                    <td className='text-center'>{item.updatedQty}</td>
                                                                                    <td className='text-center'>{item.action}</td>
                                                                                    <td className='text-start text-truncate' style={{ maxWidth: 0 }}>
                                                                                        <OverlayTrigger placement='top' overlay={<Tooltip>{item.comments}</Tooltip>}>
                                                                                            <span className="d-inline-block text-truncate w-100">
                                                                                                {item.comments}
                                                                                            </span>
                                                                                        </OverlayTrigger>
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan="12" className="text-center">No History Available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                :
                                <Image className='loading-img' src={loadingIcon} alt="logo" />
                            }
                            <Row className='g-0'>
                                <Col md={12} className='table-pagination flex-wrap d-flex align-items-center justify-content-end'>
                                    <div className='d-flex align-items-center mb-3'>
                                        <span className='mx-3'> Items per page:</span>

                                        <select className='form-select' aria-label="Floating label select example" name="productType" onChange={(e) => { handlePageSize(e) }}>
                                            {pageSizeSelectOptions.map((page, index) => {
                                                return <option selected={page.value === pageDetails.pageSize} defaultValue={pageDetails.pageSize} key={index} value={page.value}>{page.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className='d-flex align-items-center mb-3'>
                                        <span className='mx-3'> {pageDetails.currentPage + " - " + totalInvList}</span>
                                        <Button className='light-btn px-3' variant="primary" disabled={pageDetails.currentPage === 1} onClick={() => handlePaginaton('PREV')}><IoChevronBackOutline className='fs-16' /></Button>
                                        <Button className='light-btn px-3 ms-3' variant="primary" disabled={totalInvList < pageDetails.pageSize || pageDetails.currentPage === Math.ceil(totalInvList / pageDetails.pageSize) ? true : false} onClick={() => handlePaginaton('NEXT')}><IoChevronForwardOutline className='fs-16' /></Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default InventoryHistory;
