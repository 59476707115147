import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Modal, Row, Table } from 'react-bootstrap';
import loader from '../../../../assets/images/loading-img.gif';
import { LuUser2 } from 'react-icons/lu';
import { FaIndianRupeeSign } from 'react-icons/fa6';
import CustomerDetailModal from '../../billing/standard/modals/other/CustomerDetailModal';
import { getOrderByInvNo } from '../../../../actions/order/CompleteOrdersAction';
import { useDispatch } from 'react-redux';
import { standardDateFormat } from '../../../../util/utility-helper';
import { updateOrder } from '../../../../actions/order/OpenOrdersActions';

let isDataChangeFlag = false;

const dateFormat = process.env.REACT_APP_DATE_FORMAT;
const TokenOrderDetailModal = ({ ...props }) => {
    const dispatch = useDispatch();

    const [modalManage, setModalManager] = useState({ modalName: '', visible: false });
    const [loading, setLoading] = useState(false);
    const [multiOrderDetails, setMultiOrderDetails] = useState({});
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        if (props.showModal) {
            if (props.selectedOrder) {
                getMultiOrderDetailByInvNo(props.selectedOrder);

            }
        }
    }, [props.showModal, props.selectedOrder]);

    const getMultiOrderDetailByInvNo = async (orderDetail) => {
        setLoading(true)
        await dispatch(getOrderByInvNo(orderDetail.invoiceNo)).then(async (response) => {
            if (response.payload) {
                setMultiOrderDetails(response.payload || []);
            }
            setLoading(false)
        })
    };

    const handleModalManager = (modalName, isVisible) => {
        setModalManager({ ...modalManage, modalName: modalName, visible: isVisible });
    };

    const printInvoice = () => {

    };

    const onCustomerDetailChange = async (newCustomer) => {
        if (JSON.stringify(newCustomer) !== JSON.stringify(multiOrderDetails.customer)) {
            try {
                setLoading(true)
                await dispatch(updateOrder({ id: multiOrderDetails.id, customer: newCustomer }))
                    .then((response) => {
                        if (response.payload && !response.payload.error) {
                            isDataChangeFlag = true;
                            getMultiOrderDetailByInvNo(multiOrderDetails);
                            props.handleToastManager({ visible: true, toastMessage: "Customer Updated!", variant: "Success" });
                        } else {
                            props.handleToastManager({ visible: true, toastMessage: "Error While Updaing Customer!", variant: "Danger" });
                        }
                    })
            } catch (err) { }
        }
    }

    const customerDetailModalProps = { showModal: (modalManage.modalName === 'customerDetailModal' && modalManage.visible === true), hideModal: () => handleModalManager('', false), customerDetails: { ...multiOrderDetails.customer || {}, orderDate: multiOrderDetails.orderDate } || null, changeCustomerDetails: onCustomerDetailChange };

    return (
        <>
            {modalManage.modalName === "customerDetailModal" && modalManage.visible === true ?
                <CustomerDetailModal {...customerDetailModalProps} />
                :
                <Modal backdrop="static" keyboard={false} className='modal-height' show={props.showModal} onHide={() => props.hideModal(isDataChangeFlag)} centered size='lg' scrollable>
                    <Modal.Header className='border-0 pb-0' closeButton>
                        <Row className='mx-0 g-3 w-100 align-items-center me-3'>
                            <Col md={12} lg={7} className='d-sm-flex d-md-flex flex-wrap d-block align-items-center pe-4'>
                                <Modal.Title className='fs-5 fw-bold me-auto'>Token Orders <span className='text-primary text-warning'>({multiOrderDetails.tags})</span></Modal.Title>
                                <Modal.Title className='fs-5 fw-bold ms-auto'>Total: <span className='text-primary text-warning'>{parseFloat(multiOrderDetails.orderTotal).toFixed(2)}</span></Modal.Title>
                            </Col>
                            <Col xs={6} md={6} lg={2}></Col>
                            <Col xs={6} md={6} lg={3}>
                                <Button className='light-btn w-100 text-truncate h-56 text-uppercase  d-flex align-items-center justify-content-center' variant="primary" onClick={() => handleModalManager('customerDetailModal', true)}>
                                    <LuUser2 className='fs-16 me-1' />Customer
                                </Button>
                            </Col>

                        </Row>
                    </Modal.Header>
                    {!loading ?
                        <Modal.Body>
                            <Row className='g-0'>
                                <Col xs={12}>
                                    <Table responsive className='inso-table'>
                                        <thead>
                                            <tr>
                                                <th className='text-center table_btn' width="5%">
                                                    <Button className='border-0 bg-transparent p-0'>
                                                        <span className={`arrow_btn`}></span>
                                                    </Button>
                                                </th>
                                                <th className='text-center' width="20%">Order Date</th>
                                                <th className='text-end' width="15%"><FaIndianRupeeSign className='' /> Amount</th>
                                                <th className='text-center' width="15%">Order Type</th>
                                                <th className='text-center' width="15%">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr >
                                                <td className='text-center table_btn'>
                                                    <Button className='border-0 bg-transparent p-0' onClick={() => setIsExpanded(!isExpanded)}>
                                                        <span className={`arrow_btn ${isExpanded ? 'expanded' : ''}`}></span>
                                                    </Button>
                                                </td>
                                                <td className='text-center'>{standardDateFormat(multiOrderDetails.orderDate, dateFormat)}</td>
                                                <td className='text-end'>{multiOrderDetails.orderTotal}</td>
                                                <td className='text-center'>
                                                    <Badge bg="success" >DINE</Badge>
                                                </td>
                                                <td className='text-center'>
                                                    {multiOrderDetails.paymentStatus === "UNPAID" ?
                                                        <Badge bg="danger">Un-Paid</Badge> :
                                                        multiOrderDetails.paymentStatus === "PAY" || multiOrderDetails.paymentStatus === "PAID" ?
                                                            <Badge bg="success" >Paid</Badge> :
                                                            multiOrderDetails.paymentStatus === "PARTIAL" ?
                                                                <Badge bg="warning">Partial</Badge> : null}
                                                </td>
                                            </tr>
                                            {isExpanded ?
                                                <tr>
                                                    <td colSpan={12} className='p-2'>
                                                        <Table responsive className='inso-table mb-0'>
                                                            <thead>
                                                                <tr>
                                                                    <th className='text-start' width="60%">Item Name</th>
                                                                    <th className='text-center' width="20%">Qty</th>
                                                                    <th className='text-end' width="20%"><FaIndianRupeeSign className='' /> Price</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {multiOrderDetails.items && multiOrderDetails.items.map((item, subIdx) => (
                                                                    <tr key={`${subIdx}`}>
                                                                        <td className='text-start'>{item.prodAlias}</td>
                                                                        <td className='text-center'>{item.qty}</td>
                                                                        <td className='text-end'>{item.price}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </td>
                                                </tr> : null}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Modal.Body >
                        : <div className="d-flex align-items-center justify-content-center" style={{ height: '400px' }} >
                            <img width='20%' src={loader} />
                        </div>}
                    <Modal.Footer className='border-0 pt-0'>
                        <Row className='g-3 w-100 justify-content-end'>
                            <Col xs={6} sm={6} md={3} lg={2}>
                                <Button className='light-btn w-100 bg-light-grey' variant="primary" onClick={printInvoice}>
                                    Print
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal >
            }
        </>
    )
}

export default TokenOrderDetailModal;