import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import AutoCompleter from "../../../../../components/common/App/AutoCompleter";
import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { getConfigureProductsList, updateProductAssembly } from "../../../../../actions/admin/AdminActions";
import { getAllProducts } from "../../../../../actions/billdesk/BillDeskActions";

let isDataChangeFlag = false;
let selectedProduct = null;
const ConfigureProductModal = ({ ...props }) => {
    const dispatch = useDispatch();

    const [configureProducts, setConfigureProducts] = useState([]);
    const [buttonState, setButtonState] = useState({ save: "disable", qtyField: "disable", addItem: "disable", });
    const [productList, setProductList] = useState([]);
    const [clearSearch, setClearSearch] = useState(false);

    useEffect(() => {
        async function callBack() {
            if (props.showModal && props.selectedProduct) {
                await fetchConfigureProds(props.selectedProduct.id);
            }
        }
        callBack();
    }, [props.showModal, props.selectedProduct]);

    const fetchConfigureProds = async (prodId) => {
        if (prodId) {
            await dispatch(getConfigureProductsList(prodId))
                .then((response) => {
                    if (response.payload) {
                        setConfigureProducts(response.payload);
                        fetchProductList(response.payload);
                    }
                })
        }
    };

    const fetchProductList = async (configureProds) => {
        await dispatch(getAllProducts())
            .then((response) => {
                if (response.payload && !response.payload.error) {
                    const prodListClone = [];
                    response.payload && response.payload.forEach(prod => {
                        const foundProd = configureProds && configureProds.find(e => (e.business_product && e.business_product.id) === prod.id);
                        if (!foundProd) {
                            prodListClone.push(prod);
                        }
                    })

                    setProductList(prodListClone);
                }
            })
    };

    const handleItemSelect = (item) => {
        if (item.selItm) {
            selectedProduct = item.selItm;
            setButtonState({ ...buttonState, qtyField: "", });
        }
    };

    const handleSave = async () => {
        const configureProdsLcl = configureProducts;
        let prepareAssembly = [];
        configureProdsLcl.forEach(prod => {
            prepareAssembly.push({ id: prod?.business_product?.id, prdQtyConsume: prod.prdQtyConsume });
        });
        await dispatch(updateProductAssembly(
            { body: { assemblies: prepareAssembly }, productId: props.selectedProduct.id }
        )).then((response) => {
            if (response.payload && !response.payload.error) {
                fetchConfigureProds(props.selectedProduct.id);
                props.handleToastManager({ visible: true, toastMessage: 'Product Assembly updated.', variant: "Success" })
            } else {
                props.handleToastManager({ visible: true, toastMessage: response.payload.error, variant: "Danger" })
            }
        })
    };

    const handleQtyChange = (event) => {
        if (event.target.value !== '') {
            selectedProduct = { ...selectedProduct, prdQtyConsume: event.target.value }
            setButtonState({ ...buttonState, addItem: "" });
        } else {
            setButtonState({ ...buttonState, addItem: "disable" });
        }
    };

    const addItem = () => {
        document.getElementById('prdQtyConsume').value = '';
        setButtonState({ ...buttonState, addItem: "disable", qtyField: "disable", save: "", search: true });
        const configureProductsClone = [...configureProducts];
        configureProductsClone.push({ newAdded: true, prdQtyConsume: selectedProduct.prdQtyConsume, business_product: selectedProduct });
        setConfigureProducts(configureProductsClone);
        fetchProductList(configureProductsClone);
        setClearSearch(true);
    };

    const deleteItem = (itemIndex) => {
        if (itemIndex > -1) {
            const newConfigureProducts = configureProducts;
            newConfigureProducts.splice(itemIndex, 1);
            setConfigureProducts([...newConfigureProducts])
        }

    };

    const onSearchChange = (value) => {
        if (value === "") {
            setButtonState({ ...buttonState, addItem: "disable", qtyField: "disable" });
        }
    };

    const autoCompleterProps = {
        name: "prodAlias", data: productList, extraLabel: 'itemNumber', placeholder: "Search Product by name", onChange: handleItemSelect, onInputChange: onSearchChange, clearSearch: clearSearch, setClearSearch: setClearSearch
    };

    return (
        <Modal size="lg" backdrop="static" keyboard={false} scrollable centered show={props.showModal} onHide={() => props.hideModal(isDataChangeFlag)}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Configure Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ minHeight: "450px" }}>
                <Row className="g-3">
                    <Col xs={6} sm={3} md={3} lg={3}>
                        <Form.Label htmlFor="floatingInputCustom" className='d-flex align-items-center'>
                            Product Name<span className='ms-auto'>-</span>
                        </Form.Label>
                    </Col>
                    <Col xs={6} sm={3} md={3} lg={3}>
                        <Form.Label htmlFor="floatingInputCustom">
                            {props.selectedProduct?.prodAlias}
                        </Form.Label>
                    </Col>
                    <Col xs={6} sm={3} md={3} lg={3}>
                        <Form.Label htmlFor="floatingInputCustom" className='d-flex align-items-center'>
                            Price<span className='ms-auto'>-</span>
                        </Form.Label>
                    </Col>
                    <Col xs={6} sm={3} md={3} lg={3}>
                        <Form.Label htmlFor="floatingInputCustom">
                            {props.selectedProduct?.price}
                        </Form.Label>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col xs={6} sm={3} md={3} lg={3}>
                        <Form.Label htmlFor="floatingInputCustom" className='d-flex align-items-center'>
                            Item Number<span className='ms-auto'>-</span>
                        </Form.Label>
                    </Col>
                    <Col xs={6} sm={3} md={3} lg={3}>
                        <Form.Label htmlFor="floatingInputCustom">
                            {props.selectedProduct?.itemNumber}
                        </Form.Label>
                    </Col>
                    <Col xs={6} sm={3} md={3} lg={3}>
                        <Form.Label htmlFor="floatingInputCustom" className='d-flex align-items-center'>
                            HSN Code<span className='ms-auto'>-</span>
                        </Form.Label>
                    </Col>
                    <Col xs={6} sm={3} md={3} lg={3}>
                        <Form.Label htmlFor="floatingInputCustom">
                            {props.selectedProduct?.hsn}
                        </Form.Label>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col md={8} lg={8} >
                        <AutoCompleter  {...autoCompleterProps} />
                    </Col>
                    <Col md={2} lg={2} className="p-0">
                        <Form.Floating className="">
                            <Form.Control disabled={buttonState.qtyField === "disable"} type="number" autoComplete='off' name="name" id="prdQtyConsume" placeholder="Enter qty of product" onChange={handleQtyChange} />
                            <Form.Label htmlFor="prdQtyConsume">Consume Qty</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={2} lg={2}>
                        <Button className="light-btn h-56 w-100 bg-light-grey  btn btn-primary"
                            disabled={buttonState.addItem === 'disable'}
                            onClick={addItem}
                        >
                            Add Item
                        </Button>
                    </Col>

                </Row>
                <Col md={12} >
                    <Table responsive className='inso-table'>
                        <thead>
                            <tr>
                                <th className='text-start' width="30%">Product Name</th>
                                <th className='text-start' width="30%">Qty</th>
                                <th className='text-center' width="20%">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {configureProducts.length > 0 ? (
                                configureProducts.map((item, index) => (
                                    <tr key={index}>
                                        <td className='text-start'>{item.business_product && item.business_product.prodAlias}</td>
                                        <td className='text-start'>{item?.prdQtyConsume || "-"}</td>
                                        <td className='text-center'>
                                            < OverlayTrigger placement='top' overlay={< Tooltip > Delete Product </Tooltip>}>
                                                <Button className="border-0 bg-transparent p-0 ms-3"
                                                    onClick={() => deleteItem(index)}
                                                >
                                                    <AiOutlineDelete className="fs-16 text-dark " />
                                                </Button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="12" className="text-center">No products available</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Modal.Body>
            <Modal.Footer className='border-0 pt-0'>
                <Row className='g-3 w-100 justify-content-end'>
                    <Col md={3} lg={2} >
                        {!buttonState.loading ?
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" disabled={buttonState.disable} onClick={handleSave}>Save</Button>
                            : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    size="sm"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    )
}

export default ConfigureProductModal;