import React from "react";
import ProductCard from "./ProductCard";
import { Card } from "react-bootstrap";
import ProductCardSmall from "./ProductCardSimple";

const showProductImageFlag = process.env.REACT_APP_SHOW_PRODUCT_IMAGE;
const ProductCardView = ({ productList, handleItemClick }) => {
    return (
        <>
            {productList.map(product => {
                if (product !== null) {
                    let perDisc = 0;
                    if (product.discPrice !== undefined) {
                        perDisc = (((parseInt(product.price)) - parseInt(product.discPrice)) / parseInt(product.price) * 100);
                    }
                    return (
                        showProductImageFlag === "Y" ?
                            <ProductCard product={product} handleItemClick={handleItemClick} perDisc={perDisc} /> :
                            <ProductCardSmall product={product} handleItemClick={handleItemClick} perDisc={perDisc} />
                    )
                } else {
                    return (
                        <Card className="p-main-box-small-new" style={{ opacity: 0 }}>
                        </Card>
                    )
                }
            })}
        </>
    )
}

export default ProductCardView;