import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Image, Nav, NavDropdown, Navbar, Row } from 'react-bootstrap';
import logoIcon from '../../assets/images/Nlogo.jpeg';
import userImg from '../../assets/images/user.png';
import { TbHistory, TbMessage } from 'react-icons/tb';
import { IoChevronBack } from 'react-icons/io5';
import { LuLogOut, LuPackageOpen, LuUser2 } from 'react-icons/lu';
import { RiAdminLine, RiFullscreenFill } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import { MdOutlineManageAccounts, MdOutlinePostAdd, MdPassword } from 'react-icons/md';
import { invalidateSession } from '../../config/appSession';
import { useDispatch, useSelector } from 'react-redux';
import { changeCrudOperOnAdmin, refreshNewOrderProcess } from '../../slice/BillDeskSlice';
import { FaIndianRupeeSign } from 'react-icons/fa6';
import imgOne from '../../assets/images/total-sales-img.png';
import { changeOrderFilter } from '../../slice/order/CompleteOrderSlice';
import noImage from '../../assets/images/no_store_found.png';

const businessLogo = process.env.REACT_APP_BUSSINESS_LOGO;
const businessName = process.env.REACT_APP_BUSINESS_NAME;
const initialSalesTotalsState = { totalSale: 0, cancelTotal: 0 };

const NavBar = ({ menuIconClick, pageTitle }) => {
    const location = useLocation();
    const userRole = localStorage.getItem('USER_ROLE');
    const dispatch = useDispatch();

    const [selectedBadge, setSelectedBadge] = useState('')

    const [salesTotals, setSalesTotal] = useState({ ...initialSalesTotalsState })

    const enableCrudOperation = useSelector((state) => state.billDesk.enableCrudOperation);
    const completeOrderList = useSelector((state) => state.completeOrder.completeOrderList);

    useEffect(() => {
        if (completeOrderList) {
            let salesTotalsLcl = { ...initialSalesTotalsState };
            completeOrderList.forEach(orders => {
                if (orders.status === "CANCELED") {
                    salesTotalsLcl.cancelTotal = salesTotalsLcl.cancelTotal = orders.orderTotal
                } else {
                    salesTotalsLcl.totalSale = salesTotalsLcl.totalSale + orders.orderTotal
                }
            })
            setSalesTotal({ ...salesTotalsLcl });
        }
    }, [completeOrderList]);

    const logOutSession = () => {
        invalidateSession();
    };

    const handleCrudOperOnAdminRole = () => {
        dispatch(changeCrudOperOnAdmin(!enableCrudOperation));
    };

    const createNewOrder = () => {
        dispatch(refreshNewOrderProcess(true));
    };

    const applyOrdersFilter = (filter) => {
        dispatch(changeOrderFilter(filter));
    };
    return (
        <>
            {userRole !== 'BUSINESS_ADMIN' ?
                location.pathname === '/openOrders' ?
                    <Navbar collapseOnSelect bg='white' className='pos-top-navbar border-bottom' expand="md">
                        <Container fluid className='px-3'>
                            <Navbar.Brand className=''>
                                <h4 className="fw-bold fs-3 mb-0"> {pageTitle}</h4>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                                <Nav className="flex-grow-1 flex-row mt-3 mt-md-0 justify-content-end align-items-center">
                                    <Button size='lg' className='light-btn h-56 px-3 d-flex align-items-center text-uppercase' variant="primary" href="/home">
                                        <IoChevronBack className='fs-16 me-1' /> Back
                                    </Button>
                                    {/* <Button size='lg' className='light-btn h-56 px-3 d-flex align-items-center ms-3 text-uppercase' variant="primary" href="/orderManage">
                                        Order Manage <IoChevronForward className='fs-16 ms-1' />
                                    </Button> */}
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar >
                    :
                    location.pathname === '/completeOrders' ?
                        <Navbar collapseOnSelect bg='white' className='pos-top-navbar border-bottom' expand="md">
                            <Container fluid className='px-3'>
                                <Navbar.Brand className=''>
                                    <h4 className="fw-bold fs-3 mb-0"> {pageTitle}</h4>
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                                    <Nav className="flex-grow-1 flex-sm-row mt-3 mt-md-0 justify-content-end align-items-center">
                                        <Button size='lg' className='light-btn h-56 px-3 d-flex align-items-center text-uppercase' variant="primary" href="/home">
                                            <IoChevronBack className='fs-16 me-1' /> Back
                                        </Button>
                                        {userRole === 'BUSINESS_ADMIN' ?
                                            <>
                                                <Card className='summary-card summary-bg-red ms-3 mt-3 mt-sm-0' role='button'>
                                                    <Card.Body className='summary-box'>
                                                        <Row className=' g-0 mx-0'>
                                                            <Col xs={12} md={12} className='d-flex align-items-center justify-content-between'>
                                                                <div onClick={() => applyOrdersFilter('CANCELLED')}>
                                                                    <span className="label-text">
                                                                        Cancelled Sales
                                                                    </span>
                                                                    <span className="value-text">
                                                                        <FaIndianRupeeSign className='icon' />{salesTotals.cancelTotal}
                                                                    </span>
                                                                </div>
                                                                <Image src={imgOne} />
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                                <Card className='summary-card summary-bg-green ms-3 mt-3 mt-sm-0' role='button'>
                                                    <Card.Body className='summary-box'>
                                                        <Row className=' g-0 mx-0'>
                                                            <Col xs={12} md={12} className='d-flex align-items-center justify-content-between'>
                                                                <div onClick={() => applyOrdersFilter('COMPLETED')}>
                                                                    <span className="label-text">
                                                                        Total Sales
                                                                    </span>
                                                                    <span className="value-text">
                                                                        <FaIndianRupeeSign className='icon' />{salesTotals.totalSale}
                                                                    </span>
                                                                </div>
                                                                <Image src={imgOne} />
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </> : null}
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar >
                        :
                        location.pathname === '/orderManage' ?
                            <Navbar collapseOnSelect bg='white' className='pos-top-navbar border-bottom' expand="lg">
                                <Container fluid className='px-3'>
                                    <Navbar.Brand className=''>
                                        <h4 className="fw-bold fs-3 mb-0">{pageTitle}</h4>
                                    </Navbar.Brand>
                                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-around">
                                        <Nav className="flex-grow-1 flex-row mt-3 mt-lg-0 justify-content-end align-items-center">
                                            <span className='d-flex align-items-center'>
                                                <Button style={{ width: '100px' }} variant="primary" className={`ms-3 px-3 light-btn h-56 border-0 bg-dine ${selectedBadge === "DINE" ? "text-decoration-underline" : ""}`}>DINE</Button>
                                                <Button style={{ width: '100px' }} variant="primary" className={`ms-3 px-3 light-btn h-56 border-0 bg-pick ${selectedBadge === "PICK" ? "text-decoration-underline" : ""}`} >PICK</Button>
                                                <Button style={{ width: '100px' }} variant="primary" className={`ms-3 px-3 light-btn h-56 border-0 bg-delivery ${selectedBadge === "DELIVERY" ? "text-decoration-underline" : ""}`} >DELIVERY</Button>
                                                <Button style={{ width: '100px' }} variant="primary" className={`ms-3 px-3 light-btn h-56 border-0 bg-drive ${selectedBadge === "DRIVE" ? "text-decoration-underline" : ""}`} >DRIVE</Button>
                                            </span>
                                        </Nav>
                                        <Nav className="flex-grow-1 flex-row mt-3 mt-lg-0 justify-content-end align-items-center">
                                            <span className='d-flex align-items-center'>
                                                <Button size='lg' className='light-btn h-56 px-3 d-flex align-items-center text-uppercase' variant="primary" href="/openOrders">
                                                    <IoChevronBack className='fs-16 me-1' />Back
                                                </Button>
                                                <Button size='lg' className='light-btn h-56 px-4 d-flex align-items-center ms-3' variant="primary">
                                                    <RiFullscreenFill className='fs-16' />
                                                </Button>
                                            </span>
                                        </Nav>
                                    </Navbar.Collapse>
                                </Container>
                            </Navbar >
                            :
                            location.pathname === '/home' ?
                                <Navbar collapseOnSelect bg='white' className='pos-top-navbar filter-shadow' expand="lg" >
                                    <Container fluid className='px-3'>
                                        <Navbar.Brand className='logo_icon'>
                                            <Image src={logoIcon ? logoIcon : noImage} />
                                        </Navbar.Brand>

                                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                                            <Nav className="flex-grow-1 justify-content-end align-items-center">
                                                {/* <Button size='lg' className='me-lg-3 mt-3 mt-lg-0 light-btn h-56 px-3 text-secondary text-uppercase position-relative' variant="primary" >
                                                    <span class="position-absolute top-0 start-100 translate-middle p-2 bg-light-blue border border-light rounded-circle">
                                                        <span class="visually-hidden">New alerts</span>
                                                    </span>
                                                    Disconnected
                                                </Button> */}

                                                <Nav.Link onClick={createNewOrder} ><MdOutlinePostAdd className='me-2 fs-16' /> New Orders</Nav.Link>
                                                <Nav.Link href="/openOrders"><LuPackageOpen className='me-2 fs-16' /> Open Orders</Nav.Link>
                                                <Nav.Link href="/completeOrders"><TbHistory className='me-2 fs-16' /> Complete Orders</Nav.Link>

                                                <NavDropdown className='' align="end" title={<>{businessName}</>} id="dropdown-menu-align-end">
                                                    <NavDropdown.Item id='profile' href='/profile'><LuUser2 className='me-2 fs-16 ' /> Profile</NavDropdown.Item>
                                                    {userRole === 'BUSINESS_MANAGER' ?
                                                        <NavDropdown.Item id='manageBillDesk' active={enableCrudOperation} onClick={handleCrudOperOnAdminRole}> <MdOutlineManageAccounts className='me-2 fs-16 ' /> Manage Bill Desk</NavDropdown.Item> : null}
                                                    <NavDropdown.Item id='administration' ><RiAdminLine className='me-2 fs-16 ' /> Administration</NavDropdown.Item>
                                                    <NavDropdown.Item id='changePassword'><MdPassword className='me-2 fs-16 ' /> Change Password</NavDropdown.Item>
                                                    <NavDropdown.Item id='logout' onClick={logOutSession} href='/user/login'><LuLogOut className='me-2 fs-16 ' /> Logout</NavDropdown.Item>
                                                </NavDropdown>
                                            </Nav>
                                        </Navbar.Collapse>
                                    </Container>
                                </Navbar>
                                : null :
                <Navbar collapseOnSelect className='bg-white admin-top-navbar ' expand="lg" variant="">
                    <Container fluid className='px-0'>
                        <Navbar.Brand className='logo_icon'>
                            <Image src={logoIcon ? logoIcon : noImage} />
                        </Navbar.Brand>
                        <Navbar.Brand className='d-flex align-items-center me-auto me-md-0'>
                            <label className='hamburger-menu'>
                                <input type="checkbox" onClick={menuIconClick} />
                            </label>
                            <h4 className='fw-bold fs-3 mb-0 d-none d-md-block ms-3'>{pageTitle}</h4>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responssive-navbar-nav">
                            <Nav className="flex-grow-1 justify-content-end align-items-center">
                                {/* <Nav.Link className='d-none d-lg-flex ms-3' href="#2"><TbMessage className='fs-16' /></Nav.Link>
                            <Nav.Link className='d-none d-lg-flex ms-3' href="#2"><IoNotificationsOutline className='fs-16' /></Nav.Link> */}
                                {location.pathname === '/completeOrders' ?
                                    <>
                                        <Card className='summary-card summary-bg-red ms-3 mt-3 mt-sm-0' role='button'>
                                            <Card.Body className='summary-box'>
                                                <Row className=' g-0 mx-0'>
                                                    <Col xs={12} md={12} className='d-flex align-items-center justify-content-between'>
                                                        <div onClick={() => applyOrdersFilter('CANCELLED')}>
                                                            <span className="label-text">
                                                                Cancelled Sales
                                                            </span>
                                                            <span className="value-text">
                                                                <FaIndianRupeeSign className='icon' />{salesTotals.cancelTotal}
                                                            </span>
                                                        </div>
                                                        <Image src={imgOne} />
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                        <Card className='summary-card summary-bg-green ms-3 mt-3 mt-sm-0' role='button'>
                                            <Card.Body className='summary-box'>
                                                <Row className=' g-0 mx-0'>
                                                    <Col xs={12} md={12} className='d-flex align-items-center justify-content-between'>
                                                        <div onClick={() => applyOrdersFilter('COMPLETED')}>
                                                            <span className="label-text">
                                                                Total Sales
                                                            </span>
                                                            <span className="value-text">
                                                                <FaIndianRupeeSign className='icon' />{salesTotals.totalSale}
                                                            </span>
                                                        </div>
                                                        <Image src={imgOne} />
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card> </> : null}
                                <NavDropdown
                                    className='ms-auto ms-lg-3'
                                    align="end"
                                    title={
                                        <>
                                            <Image width='35px' className='me-2 rounded-circle' src={userImg} />
                                            <span className='me-2 w-100'>
                                                <p className='m-0 lh-sm fs-12'>{businessName}</p>
                                                <p className='m-0 lh-sm fs-10 fw-normal'>Admin</p>
                                            </span>
                                        </>
                                    }
                                    id="dropdown-menu-align-end"
                                >
                                    <NavDropdown.Item eventKey="1" href='/profile'><LuUser2 className='me-2 fs-16 ' /> Profile</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="2"><TbMessage className='me-2 fs-16 ' /> Inbox</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="3" onClick={logOutSession} href='/user/login'><LuLogOut className='me-2 fs-16 ' /> Logout</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>}
        </>
    );
}

export default NavBar;