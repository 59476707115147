import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { HEADERS } from "../../config/appHeaders";

const apiEndPoint = process.env.REACT_APP_API_BASE_ENDPOINT;

export const getOpenOrders = createAsyncThunk(
    'openOrders',
    async () => {
        const response = await axios.get(`${apiEndPoint}/operation/orders/OPEN`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const getOrderByToken = createAsyncThunk(
    'orderByToken',
    async (payload) => {
        const response = await axios.get(`${apiEndPoint}/operation/order/by-token/${payload}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const getOrderById = createAsyncThunk(
    'orderById',
    async (payload) => {
        const response = await axios.get(`${apiEndPoint}/operation/order/${payload}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const updateOrder = createAsyncThunk(
    'updateOrder',
    async (payload) => {
        const response = await axios.put(`${apiEndPoint}/operation/order/${payload.id}`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)