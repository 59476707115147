import React from "react";
import { Table } from 'react-bootstrap';
import { FaMinus, FaPlus, FaTrash } from "react-icons/fa6";

const Qtylist = ({ items, qtyManage, changeQtyInput }) => {
    return (
        <Table responsive >
            <thead className=" ">
                <tr>
                    <th className="text-start">Product Name</th>
                    <th className="text-center">Current Qty</th>
                    <th className="text-center">Qty</th>
                </tr>
            </thead>
            <tbody>
                {items.length !== 0 ?
                    items.map((qtyItem, key) => {
                        return (
                            <tr key={key}>
                                <td className="orderItem_name">
                                    <span className="d-block">
                                        {qtyItem.prodAlias}
                                        {/* <span className="d-block text-secondary">{qtyItem.prodAlias}</span> */}
                                    </span>
                                </td>
                                <td className="text-center">{qtyItem.oldQty}</td>
                                <td className="text-center">
                                    <span className="orderItem_qty">
                                        {qtyItem.newQty > 1 ?
                                            <span class="action-plus" onClick={() => qtyManage(qtyItem, 'MINUS')} role="button"> <FaMinus /></span> :
                                            <span class="action-del" onClick={() => qtyManage(qtyItem, 'MINUS')} role="button">
                                                <FaTrash />
                                            </span>}
                                        <input className="qtyInput" type="text" onClick={() => changeQtyInput('Edit')} value={qtyItem.newQty} />
                                        <span class="action-plus" onClick={() => qtyManage(qtyItem, 'PLUS')} role="button"><FaPlus /></span>
                                    </span>
                                </td>
                            </tr>
                        )
                    })
                    :
                    null
                }
            </tbody>
        </Table>
    )
}

export default Qtylist;