import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import NumberField from "../../../../../../components/common/App/NumberField";

const ManageQuantity = ({ ...props }) => {
    const [orderItem, setOrderItem] = useState({});

    useEffect(() => {
        if (props.showModal) {
            setOrderItem({ ...props.selectedItem });
        }
    }, [props.showModal, props.selectedItem]);

    const submit = () => {
        props.changeQtyManually(orderItem);
        props.hideModal()
    };

    const deleteData = () => {
        props.hideModal(orderItem)
    }

    return (
        <Modal size="md" backdrop="static" keyboard={false} centered show={props.showModal} onHide={props.hideModal}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Manage Quantity</Modal.Title>
            </Modal.Header>
            <>
                <Modal.Body>
                    <Row className="g-3">
                        <Col md={4} className="mx-auto">
                            <NumberField style={{ height: '56px' }} min={0} name="qty" value={parseInt(orderItem.qty)} placeholder="Product Qty" onChange={(event) => { if (event.target.value >= 0) setOrderItem({ ...orderItem, qty: parseInt(event.target.value) }) }} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='border-0 pt-0'>
                    <Row className='g-3 w-100 justify-content-center'>
                        <Col md={3} lg={3}>
                            <Button className='light-btn w-100 bg-light-grey' variant="primary" name="Save" onClick={submit}>Save</Button>
                        </Col>
                        <Col md={3} lg={3}>
                            <Button className='light-btn w-100 bg-light-grey' variant="primary" name="Cancel" onClick={deleteData}>Cancel</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </>
        </Modal >
    )
}

export default ManageQuantity;