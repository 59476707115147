import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter, useLocation } from "react-router-dom";
import { Provider, useSelector } from 'react-redux';
import NavBar from './components/common/Navbar';
import AppRoutes from './AppRoutes';
import store from './config/store';
import { isAuthenticated } from './config/appSession';
import MainSidebar from './components/common/Sidebar';
import './App.scss';
import './AppPOS.scss';
import './AppAdmin.scss';

const AppContent = () => {
  const [isAuthFlag, setisAuthFlag] = useState(false);
  const authData = useSelector((state) => state.auth.authenticated);
  const [menuCollapse, setMenuCollapse] = useState(false);
  const userRole = localStorage.getItem('USER_ROLE')

  useEffect(() => {
    setisAuthFlag(isAuthenticated());
  }, [authData]);

  const location = useLocation();

  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };

  const getPageTitle = (path) => {
    const pageTitles = {
      '/home': 'Home',
      '/profile': 'Profile',
      '/openOrders': 'Open Orders',
      '/completeOrders': 'Order History',
      '/orderManage': 'Order Management',
      '/dashboard': 'Dashboard',
      '/admin': 'Admin',
      '/products': 'Products',
      '/category': 'Category',
      '/inventory': 'Inventory Management',
      '/purchaseOrder': 'Purchase Order',
      '/inventoryHistory': 'Inventory History',
      '/resource': "Home Resource"
    };

    return pageTitles[path] || 'Dashboard';
  };
  const isSpecialPage = [
    '/home', '/cart', '/orderhistory', '/ordersummary',
    '/choosebrand', '/productdetails', '/brandinformation', '/scan'
  ].includes(location.pathname);

  return (
    <Container className='app-wrapper p-0 m-0' fluid>
      {userRole !== 'BUSINESS_ADMIN' ?
        <div className='main-panel'>
          {isAuthFlag ?
            <NavBar
              pageTitle={getPageTitle(location.pathname)} /> : null}
          <AppRoutes />
        </div> :
        <>
          {!isSpecialPage === true ?
            isAuthFlag ?
              <>
                <MainSidebar menuCollapse={menuCollapse} />
                <div className='main-panel'>
                  <NavBar menuIconClick={menuIconClick} pageTitle={getPageTitle(location.pathname)} />
                  <div className='main-panel-content'>
                    <AppRoutes />
                  </div>
                </div>
              </> : null :
            <div className='main-panel'>
              <AppRoutes />
            </div>

          }
        </>}

    </Container>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
