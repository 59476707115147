import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { getSelCategoryByStyle } from "../../../../../../util/utility-helper";
import { useDispatch } from "react-redux";
import { deleteCategory, updateCategory } from "../../../../../../actions/billdesk/BillDeskActions";
import NumberField from "../../../../../../components/common/App/NumberField";

const initialState = { catAlias: "", catType: "", icon: "", displayOrder: '', status: "", syncStatus: "N", categoryId: "" };
let isDataChangeFlag = false;

const EditCategoryModal = ({ ...props }) => {
    const dispatch = useDispatch();

    const [category, setCategory] = useState({ ...initialState });
    const [buttonState, setButtonState] = useState({ updateState: '', deleteState: '' });

    useEffect(() => {
        if (props.showModal) {
            if (props.categoryList) {
                isDataChangeFlag = false;
                const selCategory = getSelCategoryByStyle(props.categoryList, 'bg-light-yellow');
                if (selCategory) {
                    setCategory({ ...selCategory });
                    setButtonState({ updateState: '', deleteState: '' });
                }
            }
        }
    }, [props.showModal, props.categoryList]);

    const handleChange = (event) => {
        setCategory({ ...category, [event.target.name]: event.target.value });
    };

    const updateMainCategory = async () => {
        setButtonState({ ...buttonState, updateState: 'loading' });
        try {
            await dispatch(updateCategory({ ...category }))
                .then((response) => {
                    if (response.payload && !response.payload.error) {
                        isDataChangeFlag = true;
                        props.handleToastManager({ visible: true, toastMessage: "Category Update Successfully", variant: 'Success' });
                    } else {
                        props.handleToastManager({ visible: true, toastMessage: "Error While Updating Category", variant: 'Danger' });
                    }
                    setButtonState({ ...buttonState, updateState: '' });
                })
        } catch (err) { }
    };

    const deleteMainCategory = async () => {
        setButtonState({ ...buttonState, deleteState: 'loading' });
        try {
            await dispatch(deleteCategory({ ...category }))
                .then((response) => {
                    if (response.payload && !response.payload.error) {
                        props.hideModal(true)
                        props.handleToastManager({ visible: true, toastMessage: "Category Deleted Successfully", variant: 'Success' });
                    } else {
                        props.handleToastManager({ visible: true, toastMessage: "Error While Deleting Category", variant: 'Danger' });
                    }
                    setButtonState({ ...buttonState, updateState: '' });
                })
        } catch (err) { }
    };

    return (
        <Modal size="md" backdrop="static" keyboard={false} centered show={props.showModal} onHide={() => props.hideModal(isDataChangeFlag)}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Edit Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3">
                    <Col md={12}>
                        <Form.Floating className="">
                            <Form.Control className='text-capitalize' type="text" autoComplete='off' name="catAlias" id="floatingInputCustom" placeholder="Product Name" value={category.catAlias} onChange={handleChange} />
                            <Form.Label htmlFor="floatingInputCustom">Category Name</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={12}>
                        <Form.Floating className="">
                            <NumberField className='text-capitalize' autoComplete='off' name="displayOrder" id="floatingInputCustom" placeholder="Product Name" value={category.displayOrder} onChange={handleChange} />
                            <Form.Label htmlFor="floatingInputCustom">Display Order</Form.Label>
                        </Form.Floating>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-0 pt-0'>
                <Row className='g-3 w-100 justify-content-end'>
                    <Col md={3} lg={3} >
                        {buttonState.updateState !== 'loading' ?
                            <Button disabled={buttonState.deleteState === 'loading'} className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={updateMainCategory}>Save</Button>
                            : <Button className='light-btn w-100 bg-light-grey' variant="primary" disabled>
                                <Spinner as="span" size="sm" animation="border" role="status" aria-hidden="true" />
                            </Button>}
                    </Col>
                    <Col md={3} lg={3} >
                        {buttonState.deleteState !== 'loading' ?
                            <Button disabled={buttonState.updateState === 'loading'} className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={deleteMainCategory}>Delete</Button>
                            : <Button className='light-btn w-100 bg-light-grey' variant="primary" disabled>
                                <Spinner as="span" size="sm" animation="border" role="status" aria-hidden="true" />
                            </Button>}
                    </Col>
                </Row>
            </Modal.Footer>

        </Modal >
    )
}

export default EditCategoryModal;