import moment from "moment";

export const deepCopy = (data) => {
    return JSON.parse(JSON.stringify(data));
}

export const addDelay = async (time) => {
    await new Promise(resolve => setTimeout(resolve, time ? time : 0));
};

export const getProductsInCategories = (catData = []) => {
    let newArr = [];
    catData.forEach(data => {
        if (data.categories) {
            data.categories && data.categories.forEach(ca => {
                if (ca.products) {
                    ca.products && ca.products.forEach(pa => {
                        newArr.push(pa);
                    })
                }
            })
        }
    });
    return newArr;
};

export const errorMessage = (message) => {
    if (message.localeCompare('Request failed with status code 401') === 0) {
        return 'Unauthorized to access';
    } else if (message.localeCompare('Network Error') === 0) {
        return 'Failed to Connect';
    } else {
        return '';
    }
};

export const getSelCategoryByStyle = (categoryList, style) => {
    let selCategory = null
    categoryList && categoryList.forEach(list => {
        if (Array.isArray(list)) {
            list.forEach(category => {
                if (category.className === style) {
                    selCategory = category;
                }
            })
        } else {
            if (list.className === style) {
                selCategory = list;
            }
        }
    })
    return selCategory
}

export const standardDateFormat = (date, dateFormat) => {
    let newDate = null;
    if (String(dateFormat).includes('HH:mm:ss')) {
        const splitDate = String(date).split('T');
        if (splitDate) {
            newDate = splitDate[0] + " " + (splitDate[1] ? splitDate[1].substring(0, 8) : "")
        }
    } else {
        newDate = moment(new Date(date)).format(dateFormat)
    }
    return newDate;
}

export const validateFields = (mandatoryFields = [], data = {}) => {
    let flag = false;
    mandatoryFields.forEach(fields => {
        if (!data[fields] || JSON.stringify(data).includes(`"${fields}":""`)) {
            flag = true;
        }
    });
    return flag;
}

export const validateEmail = (email = '') => {
    if (email.includes('@gmail.com')) {
        return true;
    }
    return false
}