import { combineReducers } from 'redux';
import billDeskSlice from '../slice/BillDeskSlice'
import SignInSlice from '../slice/SignInSlice'
import completeOrderSlice from '../slice/order/CompleteOrderSlice';
import adminSlice from '../slice/Admin';

const rootReducer = combineReducers({
    auth: SignInSlice,
    billDesk: billDeskSlice,
    completeOrder: completeOrderSlice,
    admin: adminSlice
});

export default rootReducer;
