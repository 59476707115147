import React from "react";
import { Form } from "react-bootstrap";

function NumberField({ name, value, onChange, dafaultValue, maxLength, min = 0, decimals = null, ...others }) {
    const handleChange = (event) => {
        const { name, value, maxLength } = event.target;
        if (value.length <= maxLength) {
            onChange({ target: { name: name, value: handleDecimalValue(value) } });
        } else {
            if (maxLength === -1) {
                onChange({ target: { name: name, value: handleDecimalValue(value) } });
            }
        }
    };

    const handleDecimalValue = (value) => {
        let val = value;
        if (decimals > 0) {
            const splitVal = val.split('.');
            if (splitVal.length == 2 && splitVal[1].length > decimals) {
                val = splitVal[0] + '.' + splitVal[1].substr(0, decimals);
            }
        } else if (!decimals || decimals === 0) {
            const splitVal = val.split('.');
            if (splitVal.length > 1) {
                val = splitVal[0];
            }
        }
        return val;
    }

    return (
        <Form.Control {...others} onKeyDown={(e) => ["e", "E", "+", "-", !decimals ? "." : null].includes(e.key) && e.preventDefault()} inputMode="numeric" pattern="[0-9]*" min={min} type="number" maxLength={maxLength} autoComplete='off' name={name} id="floatingInputCustom" value={value} defaultValue={dafaultValue} onChange={handleChange} />
    )
}

export default NumberField;