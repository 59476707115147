import React, { useState, useEffect } from 'react';
import { Button, Col, Dropdown, Form, Image, Nav, OverlayTrigger, Row, Tab, Table, Tooltip } from 'react-bootstrap';
import { TbEdit } from 'react-icons/tb';
import { AiOutlineDelete } from 'react-icons/ai';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import AddProduct from './modals/productModal/AddProduct';
import EditProduct from './modals/productModal/EditProduct';
import { useSelector, useDispatch } from "react-redux";
import { deleteProduct, getGroupStoreData } from "../../../actions/billdesk/BillDeskActions";
import ToastManager from '../../../components/common/App/NotificationManager';
import { LuSearch } from 'react-icons/lu';
import { FaIndianRupeeSign, FaPlus, FaSort } from 'react-icons/fa6';
import Slider from 'react-slick';
import { findStoreId, isAuthenticated } from '../../../config/appSession';
import loader from '../../../assets/images/loading-img.gif';
import noImage from '../../../assets/images/no-image-logo.png';
import ConfirmDialog from '../../../components/common/App/ConfirmDialog';
import { useNavigate } from 'react-router-dom';
import ConfigureProductModal from './modals/productModal/ConfigureProductModal';
import { GrDocumentConfig } from "react-icons/gr";

let selectedProduct = null;
var settings = {
    dots: false, infinite: false, speed: 500, slidesToShow: 5, slidesToScroll: 5, initialSlide: 0,
    responsive: [
        { breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 4, infinite: false, dots: false } },
        { breakpoint: 600, settings: { slidesToShow: 3, slidesToScroll: 3, initialSlide: 3 } },
        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    ]
};

let productsListClone = [];
const pageSizeSelectOptions = [{ name: "10", value: 10 }, { name: "20", value: 20 }, { name: "50", value: 50 }];
const initialPageDetails = { currentPage: 1, pageSize: 10 };
const initalModalManagerState = { modalName: '', visible: false }
const initialToastState = { visible: false, toastMessage: '', variant: "" };
const endPoint = process.env.REACT_APP_API_BASE_ENDPOINT;

const Products = () => {
    // useDispatch hook for dispatching actions
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    // Selector to get productList from the Redux store
    const productList = useSelector((state) => state.billDesk.businessStoreData)
    // State hooks for managing the current selected category
    const [selectedCategory, setSelectedCategory] = useState("AllCategories");

    // State hooks for filter the product list to display by category.
    const [filteredProductList, setFilteredProductList] = useState([]);

    // notification message
    const [toastMessage, setToastMessage] = useState({ ...initialToastState });

    // page handler store page size and page number
    const [pageDetails, setPageDetails] = useState({ ...initialPageDetails });

    const [productSearchQuery, setProductSearchQuery] = useState("");

    const [filterType, setFilterType] = useState('FINISHED');


    const [modalManager, setModalManager] = useState({ ...initalModalManagerState });
    const [sortBy, setSortBy] = useState('latest')


    useEffect(() => {
        setPageDetails(prevState => ({ ...prevState, currentPage: 1 }));
    }, [selectedCategory]);

    // Fetch all product data on component mount
    useEffect(() => {
        if (isAuthenticated()) {
            fetchAllProductList({ productTypeFilter: "FINISHED", categoryFilter: selectedCategory });
        } else {
            navigate('/user/login')
        }
    }, []);

    // Function to fetch all product  list
    const fetchAllProductList = async ({ ...filters }) => {
        setLoading(true);
        await dispatch(getGroupStoreData({ storeId: findStoreId(), filterType: filters.productTypeFilter }))
            .then((response) => {
                if (response.payload) {
                    filterProductByCategory(response.payload, filters.categoryFilter)
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                }
            });
    };

    const filterProductByCategory = async (storeData, selCategory) => {
        if (selCategory === "AllCategories") {
            const filterProductList = [];
            await storeData && storeData.forEach(async cat => {
                if (cat.categories) {
                    await cat.categories.forEach(async subCat => {
                        if (subCat.products) {
                            await subCat.products.forEach(prod => {
                                if (prod) {
                                    filterProductList.push({ ...prod, catAlias: subCat.catAlias, mainCategory: cat.catAlias });
                                }
                            })
                        }
                    })
                }
            })
            productsListClone = filterProductList;
            setFilteredProductList(sortProductData(sortBy, filterProductList));
        } else {
            const mainCategory = storeData.find(cat => cat.catAlias === selCategory);
            if (mainCategory && mainCategory.categories) {
                const filteredList = mainCategory.categories.flatMap(cat =>
                    cat.products?.map(prod => ({ ...prod, catAlias: cat.catAlias, mainCategory: mainCategory.catAlias })) || []
                );
                productsListClone = filteredList;
                setFilteredProductList(sortProductData(sortBy, productsListClone));
            } else {
                const filteredList = storeData
                    .flatMap(mainCategory =>
                        mainCategory.categories
                            .filter(cat => cat.catAlias === selCategory)
                            .flatMap(cat =>
                                cat.products?.map(prod => ({ ...prod, catAlias: cat.catAlias, mainCategory: mainCategory.catAlias })) || []
                            )
                    );
                productsListClone = filteredList;
                setFilteredProductList(sortProductData(sortBy, productsListClone));
            }
        }
    };

    // Handle category selection
    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        filterProductByCategory(productList, category);
    };

    const handleModalManager = (modalName, visible) => {
        setModalManager({ ...modalManager, modalName, visible })
    };

    const deleteProductAction = async (item) => {
        const foundCat = foundCategoryByProduct(item);
        console.log(foundCat);
        if (foundCat) {
            await dispatch(deleteProduct({ productId: item.id, categoryId: foundCat }))
                .then((response) => {
                    if ((response.payload && !response.payload.error)) {
                        handleModalManager('', false);
                        fetchAllProductList({ productTypeFilter: "FINISHED", categoryFilter: selectedCategory });
                        handleToastManager({ visible: true, toastMessage: 'Product removed successfully from inventory stocks.', variant: "Success" })
                    } else {
                        handleToastManager({ visible: true, toastMessage: 'Failed to delete Product.', variant: "Danger" })
                    }
                })
        }
        // }
    };

    const handleToastManager = (toastStatus) => {
        setToastMessage({ ...toastMessage, ...toastStatus });
    };

    // handle user search change.
    const handleProductSearchChange = (e) => {
        setProductSearchQuery(e.target.value);
        setPageDetails(prevState => ({ ...prevState, currentPage: 1 })); // Reset page to 1
    };


    // Filter users list by product name.
    const filteredProduct = filteredProductList.filter(prod =>
        prod.prodAlias.toLowerCase().includes(productSearchQuery.toLowerCase()) ||
        prod.catAlias.toLowerCase().includes(productSearchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredProduct.length / pageDetails.pageSize);
    const indexOfLastItem = pageDetails.currentPage * pageDetails.pageSize;
    const indexOfFirstItem = indexOfLastItem - pageDetails.pageSize;
    const currentItems = filteredProduct.slice(indexOfFirstItem, indexOfLastItem);

    const handlePagination = (direction) => {
        if (direction === 'PREV' && pageDetails.currentPage > 1) {
            setPageDetails(prevState => ({ ...prevState, currentPage: prevState.currentPage - 1 }));
        } else if (direction === 'NEXT' && pageDetails.currentPage < totalPages) {
            setPageDetails(prevState => ({ ...prevState, currentPage: prevState.currentPage + 1 }));
        }
    };

    const handlePageSize = (e) => {
        const newSize = parseInt(e.target.value, 10);
        setPageDetails(prevState => ({ ...prevState, pageSize: newSize, currentPage: 1 }));
    };

    const onChangeFilterType = async (newProductTypeFilter) => {
        fetchAllProductList({ productTypeFilter: newProductTypeFilter, categoryFilter: "AllCategories" });
        setSelectedCategory("AllCategories");
        setLoading(true);
        setFilterType(newProductTypeFilter);
        setPageDetails({ ...pageDetails, currentPage: 1 })
    };

    const onDeleteConfirm = (flag) => {
        if (flag === 'YES') {
            deleteProductAction(selectedProduct);
        } else {
            handleModalManager('', false);
        }
    };

    const onHideModals = (isDataChangeFlag) => {
        if (isDataChangeFlag) {
            fetchAllProductList({ productTypeFilter: filterType, categoryFilter: selectedCategory })
        }
        handleModalManager('', false);
    };

    const sortProductData = (sortBy, productsListClone) => {
        setSortBy(sortBy);
        if (sortBy === 'latest') {
            return productsListClone.sort((a, b) => { return parseInt(b.id) - parseInt(a.id) });
        } else {
            return productsListClone.sort((a, b) => { return b.prodAlias.localeCompare(a.prodAlias) }).reverse();
        }
    };

    const foundCategoryByProduct = (selProduct) => {
        let selCategory = null;
        if (selProduct) {
            productList && productList.forEach(cat => {
                if (cat && cat.categories) {
                    cat.categories.forEach(subCat => {
                        if (subCat && subCat.products) {
                            subCat.products.forEach(prod => {
                                if (prod.id === selProduct.id) {
                                    selCategory = subCat.id;
                                }
                            })
                        }
                    })
                }
            });
        }
        return selCategory;
    };

    const configureProductModal = { showModal: (modalManager.modalName === 'configureProdModal' && modalManager.visible), hideModal: onHideModals, selectedProduct: selectedProduct ,handleToastManager: handleToastManager};
    const addProductModalProps = { showModal: (modalManager.modalName === 'addProductModal' && modalManager.visible), hideModal: onHideModals, selectedCategory: selectedCategory, selProductType: filterType, categoryList: productList, handleToastManager: handleToastManager, }
    const editProductModalProps = { showModal: (modalManager.modalName === 'editProductModal' && modalManager.visible), hideModal: onHideModals, fetchAllProductList: () => fetchAllProductList({ productTypeFilter: filterType, categoryFilter: selectedCategory }), selectedProduct: selectedProduct, selectedCategory: selectedCategory, productList: productList, handleToastManager: handleToastManager }
    const confirmDialogProps = { showModal: (modalManager.modalName === 'confirmModal' && modalManager.visible), hideModal: () => handleModalManager('', false), message: "Are you sure you want to delete the product?", handleClick: onDeleteConfirm, }

    return (
        <>
            <ToastManager toastManager={toastMessage} setToastManager={setToastMessage} />
            <Row className='g-0'>
                <Col sm={12} md={12} lg={12}>
                    <Tab.Container id="left-tabs-example" activeKey={selectedCategory}>
                        <Row className='g-3'>
                            <Col md={12}>
                                <Nav className="card-tabs">
                                    <Nav.Item>
                                        <Nav.Link active={filterType === 'FINISHED'} onClick={() => onChangeFilterType('FINISHED')}>Finished</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link active={filterType === 'ASSEMBLY'} onClick={() => onChangeFilterType('ASSEMBLY')}>Assembly</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col md={12}>
                                <Row className='g-3'>
                                    <Col md={6}>
                                        <Form.Floating className="search_bar">
                                            <span class="input-group-text search_icon" ><LuSearch /></span>
                                            <Form.Control className="search_input" id="productSearch" type="search" autoComplete='off' name="filterText" placeholder="Search By Product Name" value={productSearchQuery} onChange={handleProductSearchChange} />
                                            <Form.Label htmlFor="floatingInputCustom">Search By Product Name Or Category</Form.Label>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={3} lg={3} xl={2} className=''>
                                        <Button className='light-btn h-56 w-100' variant="primary" onClick={() => handleModalManager("addProductModal", true)} >
                                            <FaPlus className="me-1 fs-16" />
                                            Product
                                        </Button>
                                    </Col>
                                    <Col md={3} lg={3} xl={2} className=''>
                                        <Dropdown className=''>
                                            <Dropdown.Toggle className='light-btn h-56 w-100 justify-content-between px-3' id="dropdown-basic">
                                                <span>
                                                    <FaSort className="me-1 fs-16" />Sort
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => setFilteredProductList(sortProductData('latest', productsListClone))} active={sortBy === 'latest'}>Latest</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setFilteredProductList(sortProductData('name', productsListClone))} active={sortBy === 'name'}>By Name</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>

                                </Row>
                            </Col>
                            <Col md={12}>
                                <Nav className='container m-0 me-auto slider_width' >
                                    <Slider className="admin-nav-tabs" {...settings}>
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="AllCategories"
                                                onClick={() => handleCategorySelect('AllCategories')}
                                                active={selectedCategory === 'AllCategories'}
                                            >
                                                All Categories
                                            </Nav.Link>
                                        </Nav.Item>
                                        {
                                            productList && productList.length > 0 &&
                                            productList.map((category, idx) => (
                                                <Nav.Item key={idx}
                                                    onClick={() => handleCategorySelect(category.catAlias)}
                                                >
                                                    <Nav.Link
                                                        eventKey={category.catAlias}
                                                        active={selectedCategory === category.catAlias}
                                                    >
                                                        {category.catAlias}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))}
                                    </Slider>
                                </Nav>
                            </Col>
                            <Col md={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey={selectedCategory}>
                                        <Row className="g-3">
                                            <Col md={12} >
                                                <Table responsive className='inso-table'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-start' width="30%">Product Name</th>
                                                            <th className='text-start' width="15%">Sub-Category</th>
                                                            <th className='text-end' width="15%"><FaIndianRupeeSign />Price</th>
                                                            <th className='text-end' width="15%">Item Number</th>
                                                            <th className='text-center' width="15%">HSN Code</th>
                                                            <th className='text-center' width="15%">UOM</th>
                                                            <th className='text-center' width="15%">GST</th>
                                                            <th className='text-center' width="20%">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!loading ?
                                                            currentItems.length > 0 ? (
                                                                currentItems.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className='text-start'>
                                                                            <div className='d-flex align-items-center'>
                                                                                <Image width='30px' height='30px' className='me-3 object-fit-cover' style={{ borderRadius: '10px' }} src={item.icon ? endPoint + item.icon : noImage} />
                                                                                <span>{item.prodAlias}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td className='text-start'>
                                                                            <span className={`symbol ${item.typroductTypepe === 'VEG' ? 'symbol_veg' : item.productType === 'NON-VEG' ? 'symbol_nonVeg' : ''}`}>{item.catAlias}</span>
                                                                        </td>
                                                                        <td className='text-end'>{item.price}</td>
                                                                        <td className='text-end'>{item.itemNumber || "-"}</td>
                                                                        <td className='text-center'>{item.hsn || "-"}</td>
                                                                        <td className='text-center'>{item.uom}</td>
                                                                        <td className='text-center'>{item.perGst}</td>
                                                                        <td className='text-center'>
                                                                            {item.productType === 'ASSEMBLY' ?
                                                                                <OverlayTrigger placement='top' overlay={< Tooltip > Configure Product </Tooltip>}>
                                                                                    <Button
                                                                                        className="border-0 bg-transparent p-0"
                                                                                        onClick={() => { handleModalManager('configureProdModal', true); selectedProduct = item }}
                                                                                    >
                                                                                     
                                                                                        <GrDocumentConfig  className="fs-16 me-2 text-dark "/>
                                                                                    </Button>
                                                                                </OverlayTrigger> : null}
                                                                            <OverlayTrigger placement='top' overlay={< Tooltip > Edit Product </Tooltip>}>
                                                                                <Button
                                                                                    className="border-0 bg-transparent p-0"
                                                                                    onClick={() => { handleModalManager('editProductModal', true); selectedProduct = item }}
                                                                                >
                                                                                    <TbEdit className="fs-16 text-dark " />
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                            < OverlayTrigger placement='top' overlay={< Tooltip > Delete Product </Tooltip>}>
                                                                                <Button className="border-0 bg-transparent p-0 ms-3"
                                                                                    onClick={() => { handleModalManager('confirmModal', true); selectedProduct = item; }}
                                                                                >
                                                                                    <AiOutlineDelete className="fs-16 text-dark " />
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="12" className="text-center">No products available</td>
                                                                </tr>
                                                            ) :
                                                            <tr  >
                                                                <td colSpan='12'>
                                                                    <div className="d-flex align-items-center justify-content-center" style={{ height: '508px' }} >
                                                                        <img width='20%' src={loader} />
                                                                    </div>
                                                                </td>
                                                            </tr>}
                                                    </tbody>
                                                </Table>
                                                <Row className='g-0'>
                                                    <Col md={12} className='table-pagination flex-wrap d-flex align-items-center justify-content-end'>
                                                        <div className='d-flex align-items-center mb-3'>
                                                            <span className='mx-3'>Items per page:</span>
                                                            <select className='form-select' aria-label="Floating label select example" name="pageSize" onChange={handlePageSize}>
                                                                {pageSizeSelectOptions.map((page, index) => (
                                                                    <option key={index} value={page.value}>{page.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className='d-flex align-items-center mb-3'>
                                                            <span className='mx-3'>{`${pageDetails.currentPage} - ${Math.ceil(filteredProduct.length / pageDetails.pageSize)} of ${filteredProduct.length}`}</span>
                                                            <Button className='light-btn px-3' variant="primary" disabled={pageDetails.currentPage === 1} onClick={() => handlePagination('PREV')}>
                                                                <IoChevronBackOutline className='fs-16' />
                                                            </Button>
                                                            <Button className='light-btn px-3 ms-3' variant="primary" disabled={indexOfLastItem >= filteredProduct.length} onClick={() => handlePagination('NEXT')}>
                                                                <IoChevronForwardOutline className='fs-16' />
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>

                        </Row>
                    </Tab.Container>
                </Col>
            </Row >
            <ConfigureProductModal {...configureProductModal} />
            <ConfirmDialog {...confirmDialogProps} />
            <AddProduct {...addProductModalProps} />
            <EditProduct {...editProductModalProps} />
        </>
    );
};

export default Products;