import React from 'react'
import { Button, Col, Row, Toast, ToastContainer } from 'react-bootstrap';
import { IoCheckmarkCircle, IoClose, IoInformationCircle } from 'react-icons/io5';
import { RiErrorWarningFill } from 'react-icons/ri';
import { toastVariants } from '../../../util/constants';

const ToastManager = ({ ...props }) => {

    const onToastClose = () => {
        props.setToastManager({ visible: false, toastMessage: '', variant: "" });
    };

    return (
        <ToastContainer className="p-3 ToastMessage" position='top-end'>
            {toastVariants.map((toast, idx) => {
                if (props.toastManager.variant === toast.variant) {
                    return (
                        <Toast
                            bg={toast.variant.toLowerCase()}
                            key={idx}
                            show={props.toastManager.visible}
                            delay={3000}
                            autohide
                            onClick={onToastClose}
                            onClose={onToastClose}
                        >
                            <Toast.Body className={`p-3 
                            ${toast.variant === 'Primary' ? 'text-white'
                                    : toast.variant === 'Secondary' ? 'text-white'
                                        : toast.variant === 'Success' ? 'text-white'
                                            : toast.variant === 'Danger' ? 'text-white'
                                                : toast.variant === 'Dark' ? 'text-white' : ''}`}>
                                <Row className='g-3'>
                                    <Col md={12} className="toast_container">
                                        {toast.variant === 'Success' ? <IoCheckmarkCircle className='svg_icon' />
                                            : toast.variant === 'Danger' ? <RiErrorWarningFill className='svg_icon' />
                                                : toast.variant === 'Warning' ? <RiErrorWarningFill className='svg_icon' />
                                                    : toast.variant === 'Info' ? <IoInformationCircle className='svg_icon' />
                                                        : <IoCheckmarkCircle className='svg_icon' />}

                                        <span>
                                            <p>{toast.name}</p>
                                            <p>
                                                {props.toastManager.toastMessage}
                                            </p>
                                        </span>
                                        <Button className="close_icon" variant={toast.variant.toLowerCase()} onClick={onToastClose}>
                                            <IoClose className='' />
                                        </Button>
                                    </Col>
                                </Row>
                            </Toast.Body>
                        </Toast>
                    )
                }
            })}
        </ToastContainer>
    )
}

export default ToastManager;
