import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { getUserById, updateUserData } from "../../../../../actions/admin/AdminActions";
import { searchStoreList } from "../../../../../actions/inventory/InventoryActions";
import { deepCopy, standardDateFormat, } from "../../../../../util/utility-helper";

const initalButtonState = { loading: false, disable: true };
const EditMember = ({ showModal, hideModal, selectedUser, handleToastManager, terminalList }) => {
    // useDispatch hook for dispatching actions
    const dispatch = useDispatch();
    const [updatedUser, setUpdatedUser] = useState(selectedUser || []);
    const [password, setPassword] = useState(selectedUser?.password || '');
    const [storeList, setStoreList] = useState([]);
    const [userAssignTerminal, setUserAssignTerminal] = useState([]);

    // loader for button
    const [buttonState, setButtonState] = useState({ ...initalButtonState });

    useEffect(() => {
        if (showModal) {
            setButtonState({ ...initalButtonState });
        }
    }, [showModal]);

    // Update state when selectedUser changes
    useEffect(() => {
        if (selectedUser) {
            fetchUserDetails(selectedUser);
            setPassword(selectedUser.password);
            getStoreByApi();
        }
    }, [selectedUser, terminalList]);

    const fetchUserDetails = async (selectedUser) => {
        if (selectedUser) {
            await dispatch(getUserById(selectedUser.id)).then((response) => {
                if (response.payload && !response.payload.error) {
                    let userDetails = { ...response.payload };
                    let userTerminals = deepCopy([...terminalList]);
                    if (terminalList) {
                        Promise.all(
                            userTerminals.map((term) => {
                                if (userDetails.terminals.find((e) => e.id === term.id)) {
                                    term.checked = true;
                                }
                                return term;
                            })).then(() => {
                                setUserAssignTerminal(userTerminals);
                            })
                    }
                    setUpdatedUser(response.payload)
                }
            })
        }
    };

    const getStoreByApi = async () => {
        await dispatch(searchStoreList({ 'searchFilter': "" }))
            .then((response) => {
                if (response.payload && !response.payload.error) {
                    setStoreList(response.payload)
                }
            })
    };

    // Handle input changes and update the state
    const onHandleChange = (e) => {
        const { name, value } = e.target;
        setUpdatedUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
        setButtonState({ ...buttonState, disable: false });
    };

    // Handle the update button click
    const handleUserUpdate = async () => {
        try {
            setButtonState({ ...buttonState, loading: true });
            await dispatch(updateUserData({ ...updatedUser, password: password }))
                .then((response) => {
                    if ((response.payload && !response.payload.error)) {
                        handleToastManager({ visible: true, toastMessage: 'Member updated successfully.', variant: "Success" })
                        fetchUserDetails(selectedUser) // Fetch updated user list
                    } else {
                        handleToastManager({ visible: true, toastMessage: 'Failed to updated member. Please try again.', variant: "Danger" })
                    }
                    setButtonState({ ...buttonState, loading: false });
                })
        } catch (error) {
            handleToastManager({ visible: true, toastMessage: 'Failed to reset password. Please try again.', variant: "Danger" })
        } finally {
            setButtonState({ ...initalButtonState });
        }
    };

    const handleCheckbox = (event, selectedTerminal) => {
        const { checked } = event.target;
        let assignedTerminals = [...userAssignTerminal];
        const userTerminals = updatedUser?.terminals || [];
        const foundIndex = userTerminals.findIndex(e => e.id === selectedTerminal?.id);
        if (foundIndex > -1) {
            userTerminals.splice(foundIndex, 1);
        } else {
            userTerminals.push(selectedTerminal);
        }
        Promise.all(
            assignedTerminals && assignedTerminals.map(term => {
                if (term.id === selectedTerminal.id) {
                    term.checked = checked;
                }
                return term
            })
        ).then(() => {
            setUserAssignTerminal(assignedTerminals)
        })
        setUpdatedUser({ ...updatedUser, terminals: userTerminals });
        setButtonState({ ...buttonState, disable: false });
    };

    return (
        <Modal size="lg" centered backdrop="static" keyboard={false} show={showModal} onHide={hideModal}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Edit Member</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3">
                    <Col md={6}>
                        <Form.Floating>
                            <Form.Control
                                type="text"
                                autoComplete='off'
                                name="username"
                                placeholder="Name"
                                value={updatedUser.username || ''}
                                onChange={onHandleChange}
                            />
                            <Form.Label>Name</Form.Label>
                        </Form.Floating>
                    </Col>
                    {/* <Col md={6}>
                        <Form.Floating>
                            <Form.Control
                                type="email"
                                autoComplete="off"
                                name="email"
                                id="emailInput"
                                placeholder="Email"
                                value={updatedUser.email || ''}
                                onChange={onHandleChange}
                            />
                            <Form.Label htmlFor="emailInput">Email</Form.Label>
                        </Form.Floating>
                    </Col> */}
                    <Col md={6}>
                        <Form.Floating>
                            <Form.Select
                                name="status"
                                id="statusSelect"
                                placeholder="Status"
                                value={updatedUser.status || ''}
                                onChange={onHandleChange}
                            >
                                <option value="" disabled>Select Status</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </Form.Select>
                            <Form.Label htmlFor="statusSelect">Status</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={6}>
                        <Form.Floating>
                            <Form.Select
                                name="storeId"
                                id="storeIdSelect"
                                placeholder="Store Id"
                                value={updatedUser.storeId || ''}
                                onChange={onHandleChange}
                            >
                                <option value="" disabled>Select Store</option>
                                {storeList.map((store, idx) => {
                                    return (
                                        <option key={idx} value={store.id} selected={store.id === updatedUser.storeId}><>{store?.name} ({store.storeType})</></option>

                                    )
                                })}
                            </Form.Select>
                            <Form.Label htmlFor="storeIdSelect">Select Store</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={6}>
                        <Form.Floating>
                            <Form.Select
                                name="roleType"
                                id="roleTypeSelect"
                                placeholder="Role Type"
                                value={updatedUser.roleType || ''}
                                onChange={onHandleChange}
                            >
                                <option value="" disabled>Select Role Type</option>
                                <option value="Admin">Admin</option>
                                <option value="BUSINESS_ADMIN">Business Admin</option>
                                <option value="BUSINESS_MANAGER">Business Manager</option>
                            </Form.Select>
                            <Form.Label htmlFor="roleTypeSelect">Role Type</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={6}>
                        <Form.Floating>
                            <Form.Control
                                type="text"
                                autoComplete='off'
                                name="createdAt"
                                placeholder="Create Date"
                                disabled
                                value={standardDateFormat(updatedUser.createdAt, 'HH:mm:ss')}
                                onChange={onHandleChange}
                            />
                            <Form.Label>Create Date</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={6}>
                        <Form.Floating>
                            <Form.Control
                                type="text"
                                autoComplete='off'
                                name="updatedAt"
                                placeholder="Update Date"
                                disabled
                                value={standardDateFormat(updatedUser.updatedAt, 'HH:mm:ss')}
                                onChange={onHandleChange}
                            />
                            <Form.Label>Update Date</Form.Label>
                        </Form.Floating>
                    </Col>
                    {userAssignTerminal && userAssignTerminal.map((terminal, index) => {
                        return (
                            <Col md={4}>
                                <Form.Check key={index} type='checkbox' checked={terminal.checked} label={terminal.name} onClick={(event) => handleCheckbox(event, terminal)} />
                            </Col>
                        )
                    })}
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-0 pt-0'>
                <Row className='g-3 w-100 justify-content-end'>
                    <Col md={3} lg={2}>
                        {buttonState.loading === false ?
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={handleUserUpdate} disabled={buttonState.disable}>Save</Button>
                            : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    size="sm"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>}
                    </Col>

                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default EditMember;
