import React from "react";
import { Table, Badge, OverlayTrigger, Tooltip, Button, Dropdown } from "react-bootstrap";
import loader from '../../../../assets/images/loading-img.gif';
import { LuEye } from 'react-icons/lu';
import { FaIndianRupeeSign } from 'react-icons/fa6';
import { MdSpatialTracking } from "react-icons/md";
import { GiPathDistance } from "react-icons/gi";
import { TbEdit } from "react-icons/tb";

const OpenOrderTable = ({ ...props }) => {
    return (
        <div className="table-responsive" style={{minHeight:'200px'}}>
        <Table   className='inso-table'>
            <thead>
                <tr>
                    {props.currentOrderType === 'DINE' ? <th className='text-start' width='10%'>Token</th> : <th className='text-start' width='10%'>Inv #</th>}
                    <th className='text-center' width='12%'>Order Date</th>
                    <th className='text-end' width='12%'><FaIndianRupeeSign className='' /> Order Amount</th>
                    {props.currentOrderType !== 'DINE' ?
                        <th className='text-center' width='15%'>Customer/Mobile</th>
                        : null}
                    <th className='text-center' width='12%'>Order Stage</th>
                    <th className='text-center' width='12%'>Payment Status</th>
                    <th className='text-center' width='12%'>Action</th>
                </tr>
            </thead>
            <tbody>
                {props.loading !== true ?
                    props.openOrders.length !== 0 ?
                        props.openOrders.map((items, index) => {
                            return (
                                <>
                                    <tr key={index}>
                                        {props.currentOrderType === 'DINE' ?
                                            <td className='text-start'>{items.tag}</td> :
                                            <td className='text-start'>{items.tempInvoiceNo}</td>
                                        }
                                        <td className='text-center'>{items.orderDate}</td>
                                        <td className='text-end'>{items.orderTotal}</td>
                                        {props.currentOrderType !== "DINE" ?
                                            <td className='text-center'>{items?.customer?.name || items?.customer?.mobileNumber ? <>{items?.customer?.name + "/" + items?.customer?.mobileNumber}</> : "-"}</td>
                                            : null}
                                        <td className='text-center'>
                                            {props.currentOrderType !== "DINE" ?
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="success" className="mx-auto" id="dropdown-basic">
                                                        {items.orderStage}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item active={true} > {items.orderStage}</Dropdown.Item>
                                                        {props.orderStages && props.orderStages.find(e => e.stageCode === items.orderStage)?.transitions.map((sequnce, key) => {
                                                            return (
                                                                <Dropdown.Item key={key} onClick={() => props.onOrderStageChange({ ...items, newStage: sequnce.stageCode })}>{sequnce.stageName}</Dropdown.Item>
                                                            )
                                                        })}
                                                    </Dropdown.Menu>
                                                </Dropdown> : items.orderStage
                                            }
                                        </td>
                                        <td className='text-center'>
                                            {items.paymentStatus === "UNPAID" ?
                                                <Badge bg="danger">Pending</Badge> :
                                                items.paymentStatus === "PAID" ?
                                                    <Badge bg="success">Paid</Badge> :
                                                    items.paymentStatus === "PARTIAL" ?
                                                        <>
                                                            <Badge bg="warning">Partial </Badge> <span><FaIndianRupeeSign className='' />{(items.orderTotal - items.amountPaid).toFixed(2)}</span>
                                                        </> : null}
                                        </td>
                                        <td className='text-center'>
                                            {props.currentOrderType === 'DINE' ?
                                                <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
                                                    <Button
                                                        className="border-0 bg-transparent p-0"
                                                        onClick={() => { props.handleModalManager('tokenOrderDetailModal', true); props.setCurrentSelOrder(items) }}
                                                    >
                                                        <TbEdit className="fs-16 text-dark " />
                                                    </Button>
                                                </OverlayTrigger> :
                                                <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
                                                    <Button
                                                        className="border-0 bg-transparent p-0"
                                                        onClick={() => { props.handleModalManager('singleOrderDetailModal', true); props.setCurrentSelOrder(items) }}
                                                    >
                                                        <TbEdit className="fs-16 text-dark " />
                                                    </Button>
                                                </OverlayTrigger>
                                            }
                                            {props.currentOrderType === 'DELIVERY' ?
                                                <OverlayTrigger placement='top' overlay={<Tooltip>Upload Tracking No</Tooltip>}>
                                                    <Button
                                                        className="border-0 bg-transparent p-0 ms-3"
                                                        onClick={() => { props.handleModalManager('uploadTrackingNoModal', true); props.setCurrentSelOrder(items) }}
                                                    >
                                                        <GiPathDistance className="fs-5 text-dark " />
                                                    </Button>
                                                </OverlayTrigger> : null}
                                        </td>
                                    </tr >
                                </>
                            )
                        })
                        :
                        props.openOrders.length === 0 ?
                            <tr>
                                <td colSpan="12" className="text-center">No order available</td>
                            </tr>
                            : null
                    :
                    <tr  >
                        <td colSpan='12'>
                            <div className="d-flex align-items-center justify-content-center" style={{ height: '508px' }} >
                                <img width='20%' src={loader} />
                            </div>
                        </td>
                    </tr>
                }
            </tbody>
        </Table>
        </div>
    )
}

export default OpenOrderTable;