import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

const ConfirmDialog = ({ message, handleClick, showModal, hideModal }) => {

    return (
        <Modal size="md" backdrop="static" keyboard={false} centered show={showModal} onHide={hideModal} >
            <Modal.Header className='border-0 pb-0' closeButton />
            <Modal.Body>
                <Row className="g-3">
                    <Col md={12} className="text-center">
                        {message}
                    </Col>
                </Row>
            </Modal.Body >
            <Modal.Footer className='border-0 pt-0'>
                <Row className='g-3 w-100 justify-content-center'>
                    <Col md={3} lg={3} >
                        <Button className='light-btn w-100 bg-light-grey' variant="primary" onClick={() => handleClick("YES")}>Yes</Button>
                    </Col>
                    <Col md={3} lg={3} >
                        <Button className='light-btn w-100 bg-light-grey' variant="primary" onClick={() => handleClick("NO")}>No</Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
};

export default ConfirmDialog;