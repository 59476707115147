import React from 'react';
import { Button, Col, Modal, Row, } from 'react-bootstrap';

const ProcessConfirmModal = ({ ...props }) => {

    return (
        <>
            <Modal size="md" backdrop="static" keyboard={false} centered show={props.showModal} onHide={props.hideModal} >
                <Modal.Header className='border-0 pb-0' closeButton>
                    <Modal.Title className='fs-5 fw-bold'></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="g-3">
                        <Col md={12} className="text-center">
                            {props.message}
                        </Col>
                    </Row>
                </Modal.Body >
                <Modal.Footer className='border-0 pt-0'>
                    <Row className='g-3 w-100 justify-content-center'>
                        <Col md={3} lg={3} >
                            <Button className='light-btn w-100 bg-light-grey' variant="primary" name="Save" onClick={() => props.handleClick("confirmModal", true)}>Confirm</Button>
                        </Col>
                        <Col md={4} lg={4} >
                            <Button className='light-btn w-100 bg-light-grey' variant="primary" name="Save" onClick={() => props.handleClick("modifyDetailsModal", true)}>Modify Details</Button>
                        </Col>
                        <Col md={3} lg={3} >
                            <Button className='light-btn w-100 bg-light-grey' variant="primary" name="Save" onClick={() => props.handleClick("", false)}>Cancel</Button>
                        </Col>
                    </Row>

                </Modal.Footer>
            </Modal >
        </>
    )
}

export default ProcessConfirmModal;