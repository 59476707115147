import React from "react";
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const ExpandCategoryView = ({ categoryList, handleClick }) => {

    return (
        categoryList && categoryList.map((ml, index) => {
            return (
                <>
                    <Col md={4} key={index}>
                        <Row className="g-3">
                            <Col md={6} className="">
                                <OverlayTrigger placement='top' overlay={<Tooltip>{ml[0].catAlias}</Tooltip>}>
                                    <Button className={`light-btn w-100 text-truncate h-56 ${ml[0].className === undefined ? "text-secondary" : ml[0].className}`} onClick={() => handleClick(ml[0])}>{ml[0].catAlias} </Button>
                                </OverlayTrigger>
                            </Col>
                            {ml.length > 1 ?
                                <Col md={6} className="">
                                    <OverlayTrigger placement='top' overlay={<Tooltip>{ml[1].catAlias}</Tooltip>}>
                                        <Button className={`light-btn w-100 text-truncate h-56 ${ml[1].className === undefined ? "text-secondary" : ml[1].className}`} onClick={() => handleClick(ml[1])}>{ml[1].catAlias} </Button>
                                    </OverlayTrigger>
                                </Col>
                                : null}
                        </Row>
                    </Col>
                </>
            )
        }
        )
    )
}

export default ExpandCategoryView;